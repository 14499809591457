import React, { Component } from 'react';

import './index.scss';

export default class SegementControl extends Component {
  render() {
    const onSegmentClick = (value, index) => {
      this.props.onSegmentClick(value, index);
    };

    return (
      <div className="controls-container">
        <div className={`controls ready`}>
          {this.props.segments.map((item, i) => (
            <div
              key={item.value}
              className={`segment ${i === this.props.activeIndex ? 'active' : 'inactive'}`}
              ref={item.ref}>
              <input
                type="radio"
                value={item.value}
                id={item.label}
                name={this.props.name}
                onChange={() => onSegmentClick(item.value, i)}
                checked={i === this.props.activeIndex}
              />
              <label htmlFor={item.label}>{item.label}</label>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
