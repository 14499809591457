import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Toggle from 'react-toggle';

import '../ParkingSpotModal/index.scss';

const GeofenceModal = ({
  geofenceName = '',
  geofenceNotes = '',
  geofenceHasThrottleCut: throttleCut = false,
  geofenceSpeedLimit = '',
  geofenceCanPark = false,
  geofenceCanStartRide = false,
  geofenceHasPickupRequested = false,
  geofenceShowOnMap = true,
  geofenceParkingSurcharge = '',
  ...props
}) => {
  const [name, setName] = useState(geofenceName);
  const [notes, setNotes] = useState(geofenceNotes);
  const [hasThrottleCut, setHasThrottleCut] = useState(throttleCut);
  const [speedLimit, setSpeedLimit] = useState(geofenceSpeedLimit);
  const [canPark, setCanPark] = useState(geofenceCanPark);
  const [canStartRide, setCanStartRide] = useState(geofenceCanStartRide);
  const [hasPickupRequested, setHasPickupRequested] = useState(geofenceHasPickupRequested);
  const [showOnMap, setShowOnMap] = useState(geofenceShowOnMap);
  const [parkingSurcharge, setParkingSurcharge] = useState(geofenceParkingSurcharge);

  useEffect(() => setName(geofenceName), [geofenceName]);
  useEffect(() => setNotes(geofenceNotes), [geofenceNotes]);
  useEffect(() => {
    setHasThrottleCut(throttleCut);
    throttleCut && setSpeedLimit('');
  }, [throttleCut]);
  useEffect(() => setSpeedLimit(geofenceSpeedLimit), [geofenceSpeedLimit]);
  useEffect(() => setCanPark(geofenceCanPark), [geofenceCanPark]);
  useEffect(() => setCanStartRide(geofenceCanStartRide), [geofenceCanStartRide]);
  useEffect(() => setHasPickupRequested(geofenceHasPickupRequested), [geofenceHasPickupRequested]);
  useEffect(() => setShowOnMap(geofenceShowOnMap), [geofenceShowOnMap]);
  useEffect(() => setParkingSurcharge(geofenceParkingSurcharge), [geofenceParkingSurcharge]);

  const onChangeGeofenceInputValues = (event) => {
    props.onChangeGeofenceInputValues(event);
  };

  const onChangeGeofenceSegmentValues = (event) => {
    props.onChangeGeofenceSegmentValues(event);
  };

  const { selectedRestrictedAreas, currentPage } = props;
  if (selectedRestrictedAreas.length > 0) {
    const selectedArea = selectedRestrictedAreas[currentPage - 1];
    return (
      <form className="edit-market-object-form">
        <div className="form-row">
          <label>Name:</label>
          <input
            type="text"
            name="geofenceName"
            placeholder=""
            value={name}
            onChange={onChangeGeofenceInputValues}
          />
        </div>
        <div className="form-row">
          <label>Notes:</label>
          <input
            type="text"
            name="geofenceNotes"
            placeholder=""
            value={notes}
            onChange={onChangeGeofenceInputValues}
          />
        </div>
        <div className="form-row">
          <label>Throttle Cut:</label>
          <Toggle
            name="geofenceHasThrottleCut"
            checked={hasThrottleCut}
            onChange={onChangeGeofenceSegmentValues}
            className="toggle"
          />
        </div>
        <div className="form-row">
          <label>Parking Allowed:</label>
          <Toggle
            name="geofenceCanPark"
            checked={canPark}
            onChange={onChangeGeofenceSegmentValues}
            className="toggle"
          />
        </div>
        <div className="form-row">
          <label>Ride Start Allowed:</label>
          <Toggle
            name="geofenceCanStartRide"
            checked={canStartRide}
            onChange={onChangeGeofenceSegmentValues}
            className="toggle"
          />
        </div>
        <div className="form-row">
          <label>Pickup Requested:</label>
          <Toggle
            name="geofenceHasPickupRequested"
            checked={hasPickupRequested}
            onChange={onChangeGeofenceSegmentValues}
            className="toggle"
          />
        </div>
        <div className="form-row">
          <label>Speed Limit (MPH):</label>
          <input
            className="numeric"
            type="text"
            name="geofenceSpeedLimit"
            disabled={hasThrottleCut}
            placeholder="None"
            value={speedLimit}
            onChange={onChangeGeofenceInputValues}
          />
          {hasThrottleCut && (
            <span style={{ fontSize: 'smaller' }}>(Disable throttle cut to set speed limit)</span>
          )}
        </div>
        <div className="form-row">
          <label>Visible To Consumers:</label>
          <Toggle
            name="geofenceShowOnMap"
            checked={showOnMap}
            onChange={onChangeGeofenceSegmentValues}
            className="toggle"
          />
        </div>
        <div className="form-row">
          <label>Parking Surcharge:</label>
          <input
            className="numeric"
            type="text"
            name="geofenceParkingSurcharge"
            placeholder="0.0"
            value={parkingSurcharge}
            onChange={onChangeGeofenceInputValues}
          />
        </div>
        <div className="form-row">
          <label>ID:</label>
          <p>{selectedArea.id}</p>
        </div>
        <div className="form-row">
          <label>Created:</label>
          <p>{selectedArea.created_date}</p>
        </div>
        <div className="form-row">
          <label>Edited:</label>
          <p>{selectedArea.modified_date}</p>
        </div>
      </form>
    );
  } else {
    return <div />;
  }
};

GeofenceModal.propTypes = {
  onChangeGeofenceInputValues: PropTypes.func,
  onChangeGeofenceSegmentValues: PropTypes.func,
  selectedRestrictedAreas: PropTypes.array,
  geofenceCanPark: PropTypes.bool,
  geofenceCanStartRide: PropTypes.bool,
  currentPage: PropTypes.number,
  geofenceName: PropTypes.string,
  geofenceHasPickupRequested: PropTypes.bool,
  geofenceNotes: PropTypes.string,
  geofenceHasThrottleCut: PropTypes.bool,
  geofenceSpeedLimit: PropTypes.string,
  geofenceShowOnMap: PropTypes.bool,
  geofenceParkingSurcharge: PropTypes.string,
};

export default GeofenceModal;
