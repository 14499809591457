/* eslint-disable no-alert */
/* eslint-disable no-useless-computed-key */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Loader from '../../Components/Loader';
import SegmentControl from '../../Components/SegmentControl';
import { bindActionCreators } from 'redux';
import AuthenticatedPage from '../../Components/AuthenticatedPage';
import { DropdownList } from 'react-widgets';
import { connect } from 'react-redux';

import { parkingSpotCapacities } from '../../api';

import ZoneMap from '../../Components/ZoneMap';
import RouteMap from '../../Components/RouteMap';
import AddItemMap from '../../Components/AddItemMap';
import DeploymentSpotCard from '../../Components/DeploymentSpotCard';
import RouteFiltersModal from '../../Components/RouteFiltersModal';
import GeofenceModal from '../../Components/GeofenceModal';
import WarehouseModal from '../../Components/WarehouseModal';
import ParkingSpotModal from '../../Components/ParkingSpotModal';
import ScheduleRouteModal from '../../Components/ScheduleRouteModal';
import AssignRouteModal from '../../Components/AssignRouteModal';
import StatusCounts from '../../Components/StatusCounts';
import RoutedStatusCounts from '../../Components/RouteStatusCounts';
import ModalTitle from '../../Components/ModalTitle';

import { createFieldRoute } from '../../state/field_routes';
import {
  getRoutes,
  createFieldTickets,
  createScheduledRoute,
  getManualRoute,
  getRouteVehicles,
} from '../../state/routes';
import { createParkingSpot, patchParkingSpot, deleteParkingSpot } from '../../state/parking_spots';
import { getScheduledRoutes, deleteScheduledRoute } from '../../state/scheduled_routes';
import { getFeatures, getDeploymentSpots, getParkingSpot } from '../../state/zones';
import {
  getRestrictedAreasWithParams,
  createRestrictedArea,
  patchRestrictedArea,
  deleteRestrictedArea,
} from '../../state/restricted_areas';
import { getSubzones } from '../../state/subzones';
import { getOpsUsers } from '../../state/users';
import { getWarehouse } from '../../state/warehouse';
import { getActiveVehicleRouteTicket } from '../../state/vehicle';
import { getZoneVehicles, getVehiclesInPolygon, getVehiclesInArea } from '../../state/vehicles';
import { getRidesInZone } from '../../state/zone_rides';
import { setSelectedZone } from '../../state/selected_zone';
import { getOpsLocations } from '../../state/ops_locations';
import { getFieldRoutes, deleteFieldRoute } from '../../state/field_routes';
import { deleteFieldRouteTicket } from '../../state/field_route_tickets';

import { getActiveZones } from '../../util/helper';
import { Modal, Button } from 'antd';
import 'antd/dist/antd.css';
import moment from 'moment';
import {
  LOW_BATTERY_DWELL_TIME,
  DAMAGED_DWELL_TIME,
  PICKUP_REQUESTED_DWELL_TIME,
  ROUTE_VEHICLE_LIMIT,
  MAX_ROUTE_TIME,
} from '../../util/constants';

import iconTrash from '../../images/trash-icon.png';
import iconArrowPrevWhite from '../../images/arrow-prev-white.png';
import iconArrowNextWhite from '../../images/arrow-next-white.png';

import './index.scss';
import ZoneMapLegend from '../../Components/ZoneMapLegend';
import { navigate } from 'gatsby';

const ALL_STATUSES = [
  'available',
  'riding',
  'pickup_requested',
  'damaged',
  'low_battery',
  'reserved',
  'emergency_pickup',
  'picked_up',
  'damaged_collected',
  'charging',
  'missing',
  'inaccessible',
];

class ManageMarket extends Component {
  constructor(props) {
    super(props);
    const selectedZone = this.props.selected_zone
      ? this.props.zones.find((zone) => zone.id === this.props.selected_zone)
      : undefined;
    this.state = {
      loaded: true,
      open: false,
      mapPlaces: [],
      mapStartPlaces: [],
      mapEndPlaces: [],
      mapRideRoutes: [],
      mapDeploymentAreas: [],
      selectedRestrictedArea: [],
      selectedParkingSpot: undefined,
      selectedDeploymentSpot: undefined,
      mapZoom: 12,
      mapParkingSpots: [],
      mapZoneBoundary: [],
      mapCenter: {},
      mapRides: [],
      error: null,
      showZoneMap: false,
      showRouteMap: false,
      currentPage: 1,
      showDeploymentSpotCard: false,
      showParkingSpots: true,
      vehicles: [],
      showModal: false,
      showScooterToggle: false,
      showRestrictedAreaToggle: false,
      showPolygons: true,
      showPolygonToggle: false,
      selectedScooterBatteryPct: 0,
      selectedScooter: null,
      selectedSubzone: null,
      statusSelected: 0,
      selectedStatusSegment: 'all',
      selectedVehicleSegment: 'all',
      selectedScooterName: '',
      routedVehicleCounts: {
        availableCnt: 0,
        ridingCnt: 0,
        lowBatterieCnt: 0,
        pickupRequestedCnt: 0,
        emergencyPickupCnt: 0,
        pickedUpCnt: 0,
        chargingCnt: 0,
        damagedCnt: 0,
        damagedFixedCnt: 0,
        missingCnt: 0,
        inaccessibleCnt: 0,
        reservedCnt: 0,
      },
      showUncorralledOnly: false,
      showRefresh: false,
      showPolygonTypeSelector: true,
      selectedPolygonSegment: 'geofence',
      subzones: [],
      hasMapEditMode: false,
      selectedRouteScooters: [],
      routedScooters: [],
      mapVehicles: [],
      isScooterClickable: true,
      isPickingRouteLocation: false,
      selectedStartLocation: undefined,
      visible: false,
      statuses: {
        pickup_requested: false,
        damaged: false,
        low_battery: false,
        missing: false,
        inaccessible: false,
        reserved: false,
        emergency_pickup: false,
        available: false,
      },
      isBatteryPctSelected: false,
      selectedBatteryPct: 0,
      isStaleSelected: false,
      selectedStalenessOption: '1',
      selectedSubzones: [],
      vehicleLimit: undefined,
      filteredStatuses: [],
      showRouteFilterModal: false,
      showScheduleRouteModal: false,
      showCreateWarehouseAreaModal: false,
      schedule_date: undefined,
      selectedOps: undefined,
      showGeofenceModal: false,
      showParkingSpotModal: false,
      showDrawingManager: false,
      segmentStatuses: ALL_STATUSES,
      drawingControl: true,
      showFirstControlRow: false,
      showSecondControlRow: false,
      showOperators: false,
      showScooters: true,
      hasCreateRouteMode: false,
      activePolygonSegmentIndex: 0,
      activeStatusValue: 'all',
      activeScooterTypeValue: 'all',
      showAssignRouteModal: false,
      hasAssignedRoute: false,
      selectedRouteType: '1',
      active_route: undefined,
      assignedFieldRouteId: undefined,
      preRoutedVehicles: [],
      excludedRouteVehicles: [],
      hasScheduledRoute: false,
      assignedScheduledRouteId: undefined,
      selectedScheduledRouteType: '1',
      scheduleType: undefined,
      showGeofenceCreator: false,
      showParkingSpotCreator: false,
      hasDrawingManager: true,
      hasNewDrawingManager: false,
      polygon: undefined,
      statusesArr: [],
      geofenceName: undefined,
      warehouseName: undefined,
      geofenceNotes: undefined,
      geofenceHasThrottleCut: undefined,
      geofenceCanPark: undefined,
      geofenceCanStartRide: undefined,
      geofenceHasPickupRequested: undefined,
      geofenceSpeedLimit: undefined,
      geofenceParkingSurcharge: undefined,
      geofenceRidingAllowed: undefined,
      showNewParkingSpotBtn: true,
      selectedParkingSpotNotes: '',
      selectedParkingSpotCapacity: '',
      selectedParkingSpotShowOnMap: undefined,
      selectedParkingSpotMinimumVehicles: '',
      activeZones: getActiveZones(this.props.zones, this.props.admin.user_default_zones),
      selectedZone,
      selectedZoneName: '',
      parkingLatitude: undefined,
      parkingLongitude: undefined,
      parkingSubmitDisabled: true,
      parkingSpotCapacities: [],
    };
    selectedZone && this.handleZoneChange(selectedZone, 'zone_state');
  }

  componentDidUpdate(prevProps) {
    if (this.props.zones.length !== prevProps.zones.length) {
      this.setState({
        activeZones: getActiveZones(this.props.zones, this.props.admin.user_default_zones),
      });
    }
    if (prevProps.vehicles.length !== this.props.vehicles.length) {
      this.setVehicleCounts(this.props.vehicles);
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    this.timer = null;
  }

  convertSpeedToMph = (speed) =>
    speed && `${speed}`.length > 0 ? ((Number(speed) / 10) * 0.62).toFixed(1) : '';

  convertMphToSpeed = (mph) => ((Number(mph) * 10) / 0.62).toFixed(0);

  // Filter out vehicles with a null location
  getMapVehicles = (vehicles) => vehicles.filter((vehicle) => vehicle.location.latitude !== null);

  getLive = async () => {
    const params = {
      statuses: this.state.segmentStatuses,
      isInsideWarehouse: true,
    };
    if (this.state.showOperators) {
      await this.getOpsLocations();
    }
    if (this.state.showScooters) {
      await this.props.actions.getZoneVehicles(this.state.selectedZone.id, params);
      let mapVehicles = this.getMapVehicles(this.props.vehicles);
      this.setState({
        mapVehicles: mapVehicles,
      });
    }
  };

  handleZoneChange = async (zone, callType) => {
    if (!zone) {
      return;
    }
    this.setState({
      loaded: false,
      showZoneMap: false,
    });
    let selectedZone = zone;
    let selectedZoneName = '';
    if (!selectedZone.id) {
      selectedZone = this.state.selectedZone;
      selectedZoneName = this.state.selectedZoneName;
    } else {
      selectedZoneName = selectedZone.name;
    }
    await this.props.actions.getSubzones(selectedZone.id);
    await this.props.actions.getWarehouse(selectedZone.id);
    await this.props.actions.getOpsUsers(selectedZone.id);
    if (this.props.opsUsers !== undefined) {
      this.setState({
        showRefresh: true,
        selectedZone,
        selectedZoneName,
      });
      if (callType !== 'zone_state' && callType !== undefined) {
        //This is being called because a zone is saved as a state var, not because of a change in the dropdown select
        await this.props.actions.setSelectedZone(selectedZone.id);
      }
      let deploymentAreas = [];
      let parkingSpots = [];
      const subzones = this.props.subzones.map((subzone) => {
        const subzoneCoords = subzone.geofence.map((points) => {
          const coords = {
            lat: points[0],
            lng: points[1],
          };
          return coords;
        });
        return {
          subzone: subzone,
          coords: subzoneCoords,
        };
      });
      const params = {
        isInsideWarehouse: true,
        excludeRouted: false,
      };
      await this.props.actions.getZoneVehicles(this.state.selectedZone.id, params);
      let mapVehicles = this.getMapVehicles(this.props.vehicles).filter((vehicle) =>
        this.doesShow(vehicle, this.state.selectedStatusSegment, this.state.selectedVehicleSegment)
      );
      const routedScooters = this.props.vehicles.filter((vehicle) => vehicle.is_routed);
      this.setState({
        loaded: true,
        vehicles: this.props.vehicles,
        mapVehicles: mapVehicles,
      });
      if (
        this.state.show_start === false &&
        this.state.show_middle === false &&
        this.state.show_end === false
      ) {
        alert('Select one of the ride segments to generate Ride Map');
      } else {
        this.setState({
          loaded: false,
        });
        let zone_boundary_points = [];
        if (this.props.zones.length > 0) {
          this.props.zones.forEach((aZone) => {
            if (aZone.id === this.state.selectedZone.id) {
              aZone.geofence.forEach((points) => {
                const coords = {
                  lat: points[0],
                  lng: points[1],
                };
                zone_boundary_points.push(coords);
              });
            }
          });
        }
        this.setState({
          mapZoneBoundary: zone_boundary_points,
        });
        await this.getFeatures();
        const mapCenter = {
          lat: selectedZone.center.latitude,
          lng: selectedZone.center.longitude,
        };
        await this.props.actions.getDeploymentSpots(this.state.selectedZone.id);
        // Get Deployment area data for map
        this.props.deployment_spots.deploymentAreas.forEach((area) => {
          const deploymentArea = area.geofence.map((latlang) => {
            const coords = {
              lat: latlang[0],
              lng: latlang[1],
            };
            return coords;
          });
          deploymentAreas.push(deploymentArea);
        });
        this.timer && clearInterval(this.timer);
        this.timer = setInterval(this.getLive, 60000);
        // Get Parking Spot data for map
        this.props.features.parkingSpots.forEach((parkingSpot) => {
          parkingSpots.push(parkingSpot);
        });
        this.setState({
          mapCenter: mapCenter,
          mapParkingSpots: parkingSpots,
          mapDeploymentAreas: deploymentAreas,
          subzones: subzones,
          routedScooters: routedScooters,
          showZoneMap: true,
          loaded: true,
          IsPostZoneShowing: true,
          showDeploymentSpotCard: false,
          showScooterToggle: true,
          showPolygonToggle: true,
          showFirstControlRow: true,
          showSecondControlRow: true,
          segmentStatuses: this.state.segmentStatuses,
        });
      }
    } else {
      alert('There are no ops users in this zone. Contact support to resolve');
    }
  };

  getParkingSpots = async () => {
    // Get Parking Spot data for map
    await this.getFeatures();
    const parkingSpots = [];
    await this.props.features.parkingSpots.forEach((parkingSpot) => {
      parkingSpots.push(parkingSpot);
    });
    return parkingSpots;
  };

  updateRestrictedAreas = async () => {
    await this.getFeatures();
  };

  updateParkingSpots = async () => {
    const parkingSpots = await this.getParkingSpots();
    this.setState({
      mapParkingSpots: parkingSpots,
    });
  };

  doesShow = (vehicle, selectedStatusSegment, selectedVehicleSegment) => {
    let showStatus = false;
    let showVehicleType = false;
    if (
      selectedStatusSegment === 'all' &&
      vehicle.status !== 'written_off' &&
      vehicle.status !== 'renamed' &&
      vehicle.status !== 'damaged_scrapped'
    ) {
      showStatus = true;
    }
    if (
      selectedStatusSegment === 'available' &&
      (vehicle.status === 'available' || vehicle.status === 'riding')
    ) {
      showStatus = true;
    }
    if (
      selectedStatusSegment === 'pickup' &&
      (vehicle.status === 'pickup_requested' ||
        vehicle.status === 'damaged' ||
        vehicle.status === 'low_battery' ||
        vehicle.status === 'reserved' ||
        vehicle.status === 'emergency_pickup') //TODO add available + staleness (v.status == VehicleStatus.Available && v.statusStaleness > twoDaysInSeconds) from ios app
    ) {
      showStatus = true;
    }
    if (
      selectedStatusSegment === 'collected' &&
      (vehicle.status === 'picked_up' ||
        vehicle.status === 'damaged_collected' ||
        vehicle.status === 'charging')
    ) {
      showStatus = true;
    }
    if (
      selectedStatusSegment === 'issues' &&
      (vehicle.status === 'missing' || vehicle.status === 'inaccessible')
    ) {
      showStatus = true;
    }
    if (selectedStatusSegment === 'riding' && vehicle.status === 'riding') {
      showStatus = true;
    }

    if (selectedStatusSegment === 'riding' && vehicle.status === 'riding') {
      showStatus = true;
    }
    // Include vehicle if status matches pre-route filtering
    if (this.state.hasMapEditMode) {
      Object.entries(this.state.statuses).forEach(([key, value]) => {
        if (value && key === vehicle.status) {
          showStatus = true;
        }
      });
    }
    if (selectedVehicleSegment === 'all') {
      showVehicleType = true;
    } else if (selectedVehicleSegment === 'dd' && vehicle.vehicle_type === 2) {
      showVehicleType = true;
    } else if (selectedVehicleSegment === 's1' && vehicle.vehicle_type === 1) {
      showVehicleType = true;
    }
    if (showStatus && showVehicleType) {
      return true;
    } else {
      return false;
    }
  };

  handleShowLiveChange = (event) => {
    const { checked } = event.target;
    this.timer && clearInterval(this.timer);
    const refreshInterval = checked ? 5000 : 60000;
    this.timer = setInterval(this.getLive, refreshInterval);
  };

  handleShowScooterChange = async (event) => {
    this.setState({
      selectedScooter: 0,
      selectedSubzone: 0,
      selectedRouteScooters: [],
      showScooters: event.target.checked,
    });
  };

  getOpsLocations = () => {
    const params = {
      includeOperators: true,
      includeOpsScooters: false,
    };
    this.props.actions.getOpsLocations(this.state.selectedZone.id, params);
  };

  handleShowOpsChange = async (event) => {
    const showOperators = event.target.checked;
    if (showOperators) {
      this.setState({
        loaded: false,
      });
      await this.getOpsLocations();
      this.setState({
        loaded: true,
        showOperators: true,
      });
    } else {
      this.setState({
        showOperators: false,
      });
    }
  };

  getSegmentStatuses = (selectedSegment) => {
    let segmentStatuses = [];
    switch (selectedSegment) {
      case 'all':
        segmentStatuses = ALL_STATUSES;
        break;
      case 'available':
        segmentStatuses = ['available', 'riding'];
        break;
      case 'pickup':
        segmentStatuses = [
          'pickup_requested',
          'damaged',
          'low_battery',
          'reserved',
          'emergency_pickup',
        ];
        break;
      case 'collected':
        segmentStatuses = ['picked_up', 'damaged_collected', 'charging'];
        break;
      case 'issues':
        segmentStatuses = ['missing', 'inaccessible'];
        break;
      case 'riding':
        segmentStatuses = ['riding'];
        break;
      default:
        segmentStatuses = [];
    }
    return segmentStatuses;
  };

  handleStatusSegmentChange = async ({ value }) => {
    const segmentStatuses = this.getSegmentStatuses(value);
    const params = {
      statuses: segmentStatuses,
      isInsideWarehouse: true,
      excludeRouted: false,
    };
    await this.props.actions.getZoneVehicles(this.state.selectedZone.id, params);
    const mapVehicles = this.getMapVehicles(this.props.vehicles);
    this.setState({
      selectedScooter: 0,
      selectedSubzone: 0,
      mapVehicles: mapVehicles,
      activeStatusValue: value,
      segmentStatuses: segmentStatuses,
      selectedStatusSegment: value,
    });
  };

  handlePolygonTypeSegmentChange = async (val) => {
    this.setState({
      selectedPolygonSegment: val,
      selectedScooter: 0,
      selectedSubzone: 0,
      selectedRouteScooters: this.state.selectedRouteScooters,
    });
  };

  handleChangeOps = async (value, type) => {
    if (type === 'assign') {
      // Get Field Routes
      let params = {
        zone_id: this.state.selectedZone.id,
      };
      let opsRoutes = [];
      await this.props.actions.getFieldRoutes(params);
      opsRoutes = this.props.field_routes.filter((field_route) => {
        if (field_route.assigned_to === value.id && field_route.is_active === true) {
          return true;
        } else {
          return false;
        }
      });
      if (opsRoutes.length > 0) {
        this.setState({
          hasAssignedRoute: true,
          assignedFieldRouteId: opsRoutes[0].id,
        });
      }
      //check if user already has field route assigned to them
    } else if (type === 'schedule') {
      await this.props.actions.getScheduledRoutes(this.state.selectedZone.id);
      const scheduledRoutes = this.props.scheduled_routes.filter((scheduled_route) => {
        if (scheduled_route.assigned_to === value.id) {
          return true;
        } else {
          return false;
        }
      });

      if (scheduledRoutes.length > 0) {
        this.setState({
          hasScheduledRoute: true,
          assignedScheduledRouteId: scheduledRoutes[0].id,
        });
      }
    }
    this.setState({
      selectedOps: value.id,
    });
  };

  handleScooterTypeSegmentChange = async ({ value }) => {
    let mapVehicles = this.props.vehicles.filter((vehicle) =>
      this.doesShow(vehicle, this.state.selectedStatusSegment, value)
    );
    mapVehicles = this.getMapVehicles(mapVehicles);
    this.setState({
      activeScooterTypeValue: value,
      selectedScooter: 0,
      selectedSubzone: 0,
      selectedRouteScooters: this.state.selectedRouteScooters,
      mapVehicles: mapVehicles,
    });
  };

  handleUncorralledChange = (event) => this.setState({ showUncorralledOnly: event.target.checked });

  setPolygon = (polygon) => {
    let polygonCoordsArray = [];
    let coords = polygon.getPath().getArray();
    for (let i = 0; i < coords.length; i++) {
      polygonCoordsArray.push(coords[i].lat() + ',' + coords[i].lng());
    }
    this.setState({
      polygon: polygon,
      hasDrawingManager: true,
      hasNewDrawingManager: false,
    });
  };

  hideDrawingManager = () => {
    this.setState({
      hasDrawingManager: false,
      hasNewDrawingManager: false,
    });
  };

  showDrawingManager = () => {
    this.setState({
      hasDrawingManager: true,
    });
  };

  restrictedAreaClick = (rArea, lat, lng) => {
    this.setState({
      loaded: false,
    });
    let showPopup;
    let coords;
    const params = {
      lat: lat,
      lon: lng,
    };
    this.props.actions.getRestrictedAreasWithParams(this.state.selectedZone.id, params).then(() => {
      if (this.props.selectedRestrictedAreas.length > 0) {
        showPopup = true;
        coords = [this.getCoords(this.props.selectedRestrictedAreas[0].geofence)];
      } else {
        showPopup = false;
        coords = [];
      }
      const [restrictedArea] = this.props.selectedRestrictedAreas;
      this.setState({
        selectedRestrictedAreas: this.props.selectedRestrictedAreas,
        selectedRestrictedArea: coords,
        showGeofenceModal: showPopup,
        showParkingSpotModal: false,
        currentPage: 1,
        loaded: true,
      });
      // Would be nice to not have two setState calls in a row, but oh well
      this.setStateForSelectedRestrictedArea(1, restrictedArea);
    });
  };

  spotClick = async (spot, type) => {
    this.setState({
      loaded: false,
      showParkingSpotModal: false,
    });
    switch (type) {
      case 'parking_spot':
        {
          await this.props.actions.getParkingSpot(this.state.selectedZone.id, spot.id);
          const selectedSpot = this.props.parking_spot.parking_spots[0];
          this.setState({
            selectedParkingSpot: selectedSpot,
            selectedParkingSpotShowOnMap: selectedSpot.show_on_map,
            selectedParkingSpotNotes: selectedSpot.notes || '',
            selectedParkingSpotCapacity: selectedSpot.capacity ? `${selectedSpot.capacity}` : '',
            selectedParkingSpotMinimumVehicles: selectedSpot.minimum_vehicles
              ? `${selectedSpot.minimum_vehicles}`
              : '',
            showParkingSpotModal: true,
            showGeofenceModal: false,
          });
        }
        break;
      case 'deployment_spot':
        this.setState({
          selectedDeploymentSpot: spot,
          showDeploymentSpotCard: true,
        });
        break;
      default:
        throw new Error('There was a problem with your request. Please try again later');
    }
    this.setState({
      showRefresh: true,
      loaded: true,
    });
  };

  handleAssignRouteChange = async () => {};

  handleShowParkingChange = async (event) =>
    this.setState({
      showParkingSpots: event.target.checked,
      loaded: true,
      showZoneMap: true,
    });

  handleStalenessChange = (event) => this.setState({ isStaleSelected: event.target.checked });

  handleStalenessOptionChange = (event) => {
    this.setState({
      selectedStalenessOption: event.target.value,
    });
  };

  handleShowPolygonsChange = async (event) => {
    this.setState({
      loaded: false,
      showZoneMap: false,
    });
    const val = event.target.checked;
    const name = 'showPolygons';
    this.setState({
      [name]: val,
      selectedScooter: 0,
      selectedSubzone: 0,
      loaded: true,
      showZoneMap: true,
      showPolygonTypeSelector: val,
    });
  };

  setVehicleCounts = async (scooters = []) => {
    const vehicleCounts = {
      all: 0,
      pickups: 0,
      collecteds: 0,
      emergency_pickup: 0,
      inaccessible: 0,
      renamed: 0,
      low_battery: 0,
      reserved: 0,
      charging: 0,
      written_off: 0,
      available: 0,
      picked_up: 0,
      damaged: 0,
      pickup_requested: 0,
      damaged_fixed: 0,
      riding: 0,
      missing: 0,
      damaged_scrapped: 0,
      damaged_collected: 0,
    };
    scooters.forEach((vehicle) => {
      vehicleCounts[vehicle.status]++;
    });
    this.setState({
      routedVehicleCounts: vehicleCounts,
    });
  };

  getCoords = (geofence) => {
    let coords = [];
    let points = {};
    geofence.forEach((point) => {
      points = {
        lat: point[0],
        lng: point[1],
      };
      coords.push(points);
    });
    return coords;
  };

  forwardPageClick = () => {
    if (this.state.currentPage !== this.state.selectedRestrictedAreas.length) {
      const currentPage = this.state.currentPage + 1;
      const restrictedArea = this.props.selectedRestrictedAreas[currentPage - 1];
      this.setStateForSelectedRestrictedArea(currentPage, restrictedArea);
    }
  };

  backPageClick = () => {
    if (this.state.currentPage !== 1) {
      const currentPage = this.state.currentPage - 1;
      const restrictedArea = this.props.selectedRestrictedAreas[currentPage - 1];
      this.setStateForSelectedRestrictedArea(currentPage, restrictedArea);
    }
  };

  setStateForSelectedRestrictedArea = (currentPage, restrictedArea) => {
    this.setState({
      geofenceHasThrottleCut: !restrictedArea.riding_allowed,
      geofenceCanPark: restrictedArea.end_ride_allowed,
      geofenceCanStartRide: restrictedArea.can_start_ride,
      geofenceHasPickupRequested: restrictedArea.pickup_requested,
      geofenceSpeedLimit: restrictedArea.speed_limit
        ? `${this.convertSpeedToMph(restrictedArea.speed_limit)}`
        : '',
      geofenceShowOnMap: restrictedArea.show_on_map,
      geofenceParkingSurcharge: `${restrictedArea.parking_surcharge}`,
      geofenceName: restrictedArea.name || '',
      geofenceNotes: restrictedArea.notes || '',
      currentPage: currentPage,
      selectedRestrictedArea: [this.getCoords(restrictedArea.geofence)],
    });
  };

  infoWindowClick = (scooter) => {
    if (this.props.admin.external_operator || this.props.admin.external_operator) {
      alert('Restricted Page');
    } else {
      navigate('/vehicles/edit', { state: { selectedScooter: scooter } });
    }
  };

  addScooterToRouteClick = async (scooter) => {
    this.setState({
      selectedRouteScooters: [...this.state.selectedRouteScooters, scooter],
    });
    this.setVehicleCounts(this.state.selectedRouteScooters);
  };

  removeRoutedScooterClick = async (scooter) => {
    const routedScooters = [...this.state.selectedRouteScooters]; // make a separate copy of the array
    const index = routedScooters.indexOf(scooter);
    if (index !== -1) {
      routedScooters.splice(index, 1);
      this.setState({ selectedRouteScooters: routedScooters });
      this.setVehicleCounts(this.state.selectedRouteScooters);
    }
  };

  openMapClick = () => {
    this.setState({
      showZoneMap: false,
    });
    this.setState({
      selectedRestrictedArea: [],
      showZoneMap: true,
    });
  };

  deleteGeofence = async () => {
    if (window.confirm('Are you sure you want to permanently delete Geofence?')) {
      this.setState({
        loaded: false,
      });
      const selectedGeofence = this.state.selectedRestrictedAreas[this.state.currentPage - 1];
      await this.props.actions.deleteRestrictedArea(
        this.state.selectedZone.id,
        selectedGeofence.id
      );
      await this.updateRestrictedAreas();
      await this.handleCancel();
      this.setState({
        loaded: true,
      });
    }
  };

  deleteParkingSpot = async () => {
    if (window.confirm('Are you sure you want to permanently delete Parking Spot?')) {
      this.setState({
        loaded: false,
      });
      await this.props.actions.deleteParkingSpot(
        this.state.selectedZone.id,
        this.state.selectedParkingSpot.id
      );
      await this.updateParkingSpots();
      await this.handleCancel();
      this.setState({
        loaded: true,
      });
    }
  };

  scooterClick = (vehicle, newMapCenter, newZoom) => {
    this.setState({
      showZoneMap: false,
    });
    const selectedScooter = {
      lat: vehicle.location.latitude,
      lng: vehicle.location.longitude,
      vehicle,
    };
    const batteryPct = vehicle.battery_pct;
    const name = vehicle.name;
    this.setState({
      selectedScooter: selectedScooter,
      selectedScooterBatteryPct: batteryPct,
      selectedScooterName: name,
      mapCenter: newMapCenter,
      mapZoom: newZoom,
      showZoneMap: true,
    });
  };

  onClickNewRoute = () => {
    if (this.state.showScooters) {
      const segmentStatuses = this.getSegmentStatuses(this.state.selectedStatusSegment);
      this.setVehicleCounts(this.state.selectedRouteScooters);
      this.setState({
        hasMapEditMode: true,
        selectedScooter: undefined,
        hasDrawingManager: true,
        segmentStatuses: segmentStatuses,
        showDrawingManager: true,
      });
    }
  };

  createNewItem = ({ value }) => {
    switch (value) {
      case 'parking_spot':
        this.onClickNewParkingSpot();
        break;
      case 'geofence':
        this.onClickNewGeofence();
        break;
      case 'route':
        this.onClickNewRoute();
        break;
      case 'warehouse':
        this.onClickNewWarehouse();
        break;
      default:
        break;
    }
  };

  onClickPickRouteLocation = () => {
    this.setState({
      isScooterClickable: false,
      isPickingRouteLocation: true,
    });
  };

  getFeatures = async () => {
    const get_feature_params = {
      ignore_cache: 'true',
      include_hidden: 'true',
    };
    await this.props.actions.getFeatures(this.state.selectedZone.id, get_feature_params);
  };

  pickParkingSpotLocationClick = async (lat, lng) => {
    if (!isFinite(lat) || !isFinite(lng) || Math.abs(lat) > 90 || Math.abs(lng) > 180) {
      alert('Invalid coordinates');
      return;
    }
    this.setState({
      mapParkingSpots: [],
    });
    const params = [
      {
        location: {
          latitude: lat,
          longitude: lng,
        },
        is_deployment: false,
      },
    ];
    await this.props.actions.createParkingSpot(this.state.selectedZone.id, params);
    await this.getFeatures();
    const parkingSpots = await this.getParkingSpots();
    this.setState(
      {
        mapParkingSpots: parkingSpots,
      },
      this.onClickCancelSelectParkingSpotLocation()
    );
  };

  pickRouteLocationClick = (lat, lng) => {
    const startLatitude = lat;
    const startLongitude = lng;
    const selectedStartLocation = {
      lat: startLatitude,
      lng: startLongitude,
    };
    this.setState({
      selectedStartLocation: selectedStartLocation,
      hasSetLocation: true,
      isPickingRouteLocation: false,
      isScooterClickable: true,
    });
  };

  onClickAssignNow = () => {
    this.setState({
      showAssignRouteModal: true,
    });
  };

  onClickFilterRoute = () => {
    this.setState({
      visible: true,
      showScheduleRouteModal: false,
      showRouteFilterModal: true,
    });
  };

  onClickScheduleManualRoute = () => {
    this.setState({
      visible: true,
      showRouteFilterModal: false,
      showScheduleRouteModal: true,
      scheduleType: 'routed',
    });
  };

  onClickScheduleFilteredRoute = () => {
    this.setState({
      visible: true,
      showRouteFilterModal: false,
      showScheduleRouteModal: true,
      scheduleType: 'filtered',
    });
  };

  patchGeofenceClick = async () => {
    const selectedGeofence = this.state.selectedRestrictedAreas[this.state.currentPage - 1];
    let params = {};
    const {
      geofenceNotes: notes,
      geofenceName: name,
      geofenceParkingSurcharge: surcharge,
    } = this.state;
    params.name = name && name.length > 0 ? name : null;
    params.notes = notes && notes.length > 0 ? notes : null;
    params.can_start_ride = this.state.geofenceCanStartRide;
    params.riding_allowed = this.state.geofenceRidingAllowed;
    params.show_on_map = this.state.geofenceShowOnMap;
    if (
      !this.state.geofenceSpeedLimit &&
      this.state.geofenceSpeedLimit.length > 0 &&
      !isNaN(this.state.geofenceSpeedLimit)
    ) {
      params.speed_limit = this.convertMphToSpeed(this.state.geofenceSpeedLimit);
    }
    const riding_allowed = !this.state.geofenceHasThrottleCut;
    params.riding_allowed = riding_allowed;
    if (!riding_allowed) {
      params.speed_limit = null;
    }
    params.end_ride_allowed = this.state.geofenceCanPark;
    params.pickup_requested = this.state.geofenceHasPickupRequested;
    params.parking_surcharge =
      surcharge && surcharge.length > 0 && !isNaN(surcharge)
        ? parseInt(this.state.geofenceParkingSurcharge, 10)
        : 0;
    await this.props.actions.patchRestrictedArea(
      this.state.selectedZone.id,
      selectedGeofence.id,
      params
    );
    alert('Geofence successfully edited');
    await this.updateRestrictedAreas();
    await this.handleCancel();
  };

  patchParkingSpotClick = async () => {
    const params = {};
    const {
      selectedParkingSpotCapacity: capacity,
      selectedParkingSpotNotes: notes,
      selectedParkingSpotShowOnMap: showOnMap,
      selectedParkingSpotMinimumVehicles: minimumVehicles,
    } = this.state;
    params.notes = notes === '' ? null : notes;
    params.capacity = capacity === '' ? null : capacity;
    params.minimum_vehicles = minimumVehicles === '' ? null : minimumVehicles;
    params.show_on_map = showOnMap;
    await this.props.actions.patchParkingSpot(
      this.state.selectedZone.id,
      this.state.selectedParkingSpot.id,
      params
    );
    alert('Parking Spot successfully edited');
    await this.updateParkingSpots();
    await this.handleCancel();
  };

  getWarehouseModalFooter = () => {
    let saveButtonStyle = { opacity: 1.0, right: '20px', background: '#f81808', color: 'white' };
    let cancelButtonStyle = {
      opacity: 1.0,
      background: '#1890ff',
      borderColor: '#1890ff',
      color: 'white',
      right: '30px',
    };
    let warehouseModalFooter = [
      <Button key="Cancel" onClick={this.handleCancel} style={cancelButtonStyle}>
        Cancel
      </Button>,
      <Button key="Save" onClick={this.onClickSaveNewWarehouseArea} style={saveButtonStyle}>
        Save
      </Button>,
    ];
    return warehouseModalFooter;
  };

  getGeofenceModalFooter = () => {
    let saveButtonStyle = { opacity: 1.0, right: '20px', background: '#f81808', color: 'white' };
    let cancelButtonStyle = {
      opacity: 1.0,
      background: '#1890ff',
      borderColor: '#1890ff',
      color: 'white',
      right: '30px',
    };
    let geofenceModalFooter = [
      <img
        key="img"
        src={iconTrash}
        alt="icon-trash"
        className="geofence-trash-button"
        onClick={this.deleteGeofence}
      />,
      <Button key="Cancel" onClick={this.handleCancel} style={cancelButtonStyle}>
        Cancel
      </Button>,
      <Button key="Save" onClick={this.patchGeofenceClick} style={saveButtonStyle}>
        Save
      </Button>,
    ];
    return geofenceModalFooter;
  };

  getParkingSpotModalFooter = () => {
    let saveButtonStyle = { opacity: 1.0, right: '20px', background: '#f81808', color: 'white' };
    let cancelButtonStyle = {
      opacity: 1.0,
      background: '#1890ff',
      borderColor: '#1890ff',
      color: 'white',
      right: '30px',
    };
    let geofenceModalFooter = [
      <img
        key="1"
        src={iconTrash}
        alt="icon-trash"
        className="geofence-trash-button"
        onClick={this.deleteParkingSpot}
      />,
      <Button key="Cancel" onClick={this.handleCancel} style={cancelButtonStyle}>
        Cancel
      </Button>,
      <Button key="Save" onClick={this.patchParkingSpotClick} style={saveButtonStyle}>
        Save
      </Button>,
    ];
    return geofenceModalFooter;
  };

  onClickCancelRoute = () => {
    this.setState({
      hasMapEditMode: true,
      hasCreateRouteMode: false,
      showRouteMap: false,
      showZoneMap: true,
    });
    this.setVehicleCounts(this.state.selectedRouteScooters);
  };

  onClickResetRouteLocation = () => {
    this.setState({
      selectedStartLocation: undefined,
    });
  };

  onClickExitRoute = () => {
    this.setState({
      showZoneMap: true,
      showRouteMap: false,
      routePlaces: [],
      selectedRouteScooters: [],
      hasCreateRouteMode: false,
      hasMapEditMode: false,
      showDrawingManager: false,
      selectedStartLocation: undefined,
      statuses: {
        pickup_requested: false,
        damaged: false,
        low_battery: false,
        missing: false,
        inaccessible: false,
        reserved: false,
        emergency_pickup: false,
        available: false,
      },
      isBatteryPctSelected: false,
      selectedBatteryPct: 0,
      isStaleSelected: false,
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  onClickApplyFilter = async () => {
    this.setState({
      visible: false,
      loaded: false,
      selectedRouteScooters: [],
    });
    let statuses = [];
    Object.entries(this.state.statuses).forEach(([key, value]) => {
      if (value) {
        statuses.push(key);
      }
    });
    let selectedBatteryPct = 0;
    let staleness_days = 0;
    if (this.state.isStaleSelected) {
      staleness_days = this.state.selectedStalenessOption;
      statuses.push('available');
    }
    if (this.state.isBatteryPctSelected === true) {
      selectedBatteryPct = this.state.selectedBatteryPct;
    } else {
      selectedBatteryPct = 0;
    }
    let includeSubzones = false;
    if (this.state.selectedSubzones.length > 0) {
      includeSubzones = true;
    }
    const warehouseId = this.props.warehouse.id;
    const shift_start = moment().tz('America/Los_Angeles').format('MMM DD YYYY HH:mm:ss');
    const shift_end = moment(shift_start)
      .add(MAX_ROUTE_TIME, 'hours')
      .tz('America/Los_Angeles')
      .format('MMM DD YYYY HH:mm:ss');
    const vehicleLimit = this.state.vehicleLimit ? this.state.vehicleLimit : ROUTE_VEHICLE_LIMIT;
    const selectedScooters = this.state.selectedRouteScooters.map((scooter) => {
      return scooter.name;
    });
    const params = {
      zone_id: this.state.selectedZone.id,
      warehouse_id: warehouseId,
      truck_capacity: vehicleLimit,
      truck_count: 1,
      status: statuses,
      shift_start_time: shift_start,
      shift_end_time: shift_end,
      max_time: MAX_ROUTE_TIME,
      low_battery_dwell_time: 1,
      damaged_dwell_time: 1,
      pickup_requested_dwell_time: 1,
      selected_battery_pct: selectedBatteryPct,
      staleness_days: staleness_days,
      created_by: this.props.admin.id,
      includeSubzones: includeSubzones,
      subzone_ids: this.state.selectedSubzones,
      startLatitude: this.state.startLatitude,
      startLongitude: this.state.startLongitude,
      vehicleLimit: vehicleLimit,
      selectedScooters: selectedScooters,
    };
    await this.props.actions.getRoutes(params);
    let routedVehicles = [];
    this.props.routes[0].waypoints.forEach((route, index) => {
      if (index > 0) {
        const new_vehicle = {
          location: {
            latitude: route.lat,
            longitude: route.lng,
          },
          name: this.props.routes[0].names[index - 1],
          status: this.props.routes[0].statuses[index - 1][0].status,
        };
        routedVehicles.push(new_vehicle);
      }
    });
    let mapVehicles = this.props.vehicles.filter((vehicle) =>
      this.doesShow(vehicle, this.state.selectedStatusSegment, this.state.selectedVehicleSegment)
    );
    mapVehicles = this.getMapVehicles(mapVehicles);
    this.setState({
      loaded: true,
      selectedRouteScooters: routedVehicles,
      mapVehicles: mapVehicles,
      showRouteFilterModal: false,
    });
    this.setVehicleCounts(this.state.selectedRouteScooters);
  };

  handleRouteFilterModalCancel = () => {
    this.setState({
      showRouteFilterModal: false,
    });
  };

  handleScheduleRouteModalCancel = () => {
    this.setState({
      showScheduleRouteModal: false,
    });
  };

  mapClick = () => {};

  reset = async () => {
    await this.updateRestrictedAreas();
    this.setState({
      showZoneMap: true,
      showRouteMap: false,
      showGeofenceCreator: false,
      showParkingSpotCreator: false,
      showAssignRouteModal: false,
      showScooterToggle: true,
      routePlaces: [],
      selectedRouteScooters: [],
      hasCreateRouteMode: false,
      hasMapEditMode: false,
      showDrawingManager: false,
      selectedStartLocation: undefined,
      statuses: {
        pickup_requested: false,
        damaged: false,
        low_battery: false,
        missing: false,
        inaccessible: false,
        reserved: false,
        emergency_pickup: false,
        available: false,
      },
      isBatteryPctSelected: false,
      selectedBatteryPct: 0,
      isStaleSelected: false,
      showScheduleRouteModal: false,
    });
  };

  subzoneClick = (subzone, newMapCenter, newZoom, lat, lng) => {
    this.setState({
      showZoneMap: false,
    });
    const selectedSubzone = {
      lat: lat,
      lng: lng,
      subzone,
    };
    this.setState({
      selectedSubzone: selectedSubzone,
      mapCenter: newMapCenter,
      mapZoom: newZoom,
      showZoneMap: true,
    });
  };

  hideModal = () => {
    this.setState({
      showModal: false,
    });
  };

  getLocationLabel = () => {
    let lblText = 'Warehouse';
    if (this.state.selectedStartLocation) {
      const startLatitude = Number.parseFloat(this.state.selectedStartLocation.lat).toFixed(4);
      const startLongitude = Number.parseFloat(this.state.selectedStartLocation.lng).toFixed(4);
      lblText = (
        <Fragment>
          <label>
            {startLatitude},{startLongitude}
          </label>
          <button className="reset-start-location-btn" onClick={this.onClickResetRouteLocation}>
            RESET
          </button>
        </Fragment>
      );
    }
    return <span className="">{lblText}</span>;
  };

  handleStatusesChange = (event) => {
    const val = event.target.checked;
    const name = event.target.name;
    let updatedStatuses = Object.assign({}, this.state.statuses, { [name]: val });
    this.setState({
      statuses: updatedStatuses,
    });
  };

  handleBatteryPctChange = (event) => {
    this.setState({
      isBatteryPctSelected: event.target.checked,
    });
  };

  showNewGeofenceBtn = () => {
    if (
      this.state.selectedPolygonSegment === 'geofence' &&
      !this.state.hasMapEditMode &&
      !this.state.hasCreateRouteMode &&
      !this.state.isPickingRouteLocation
    ) {
      return true;
    } else {
      return false;
    }
  };

  onClickNewGeofence = async () => {
    const showNewGeofenceBtn = this.showNewGeofenceBtn();
    if (showNewGeofenceBtn) {
      this.setState({
        showGeofenceCreator: true,
        showZoneMap: false,
        showScooterToggle: false,
        showParkingSpotCreator: false,
      });
    }
  };

  onClickNewWarehouse = async () => {
    const showNewWarehouseBtn = this.showNewGeofenceBtn();
    if (showNewWarehouseBtn) {
      this.setState({
        showWarehouseCreator: true,
        showGeofenceCreator: false,
        showZoneMap: false,
        showScooterToggle: false,
        showParkingSpotCreator: false,
      });
    }
  };

  onClickNewParkingSpot = async () => {
    const showNewParkingSpotBtn = this.showNewGeofenceBtn();
    if (showNewParkingSpotBtn) {
      this.setState({
        showParkingSpotCreator: true,
        showZoneMap: false,
        showScooterToggle: false,
        showGeofenceCreator: false,
      });
    }
  };

  onClickGetRoute = async () => {
    const selectedScooters = this.state.selectedRouteScooters.map((scooter) => {
      return scooter.name;
    });
    if (selectedScooters.length < 2) {
      alert('Please select 2 or more scooters to create a Route');
    } else {
      this.setState({
        loaded: false,
      });
      const warehouseId = this.props.warehouse.id;
      const shift_start = moment().tz('America/Los_Angeles').format('MMM DD YYYY HH:mm:ss');
      const shift_end = moment(shift_start)
        .add(23, 'hours')
        .tz('America/Los_Angeles')
        .format('MMM DD YYYY HH:mm:ss');
      const params = {
        zone_id: this.state.selectedZone.id,
        warehouse_id: warehouseId,
        truck_capacity: selectedScooters.length,
        truck_count: 1,
        status: [],
        shift_start_time: shift_start,
        shift_end_time: shift_end,
        max_time: 23,
        low_battery_dwell_time: LOW_BATTERY_DWELL_TIME,
        damaged_dwell_time: DAMAGED_DWELL_TIME,
        pickup_requested_dwell_time: PICKUP_REQUESTED_DWELL_TIME,
        selected_battery_pct: 0,
        staleness_days: 0,
        created_by: this.props.admin.id,
        includeSubzones: false,
        subzone_ids: [],
        startLatitude: undefined,
        startLongitude: undefined,
        selectedScooters: selectedScooters,
        vehicleLimit: 0,
      };
      await this.props.actions.getRoutes(params);
      let waypoint = {};
      let waypoints = [];
      let routeWaypoints = [];
      const route_id = this.props.routes[0].route_id;
      this.props.routes[0].waypoints.forEach((route, index) => {
        let vehicle_name;
        let vehicle_hash;
        let vehicle_status;
        if (index > 0) {
          waypoint = {
            lat: route.lat,
            lng: route.lng,
          };
          vehicle_name = this.props.routes[0].names[index - 1];
          vehicle_hash = this.props.routes[0].hashes[index - 1];
          vehicle_status = this.props.routes[0].statuses[index - 1][0].status;
          routeWaypoints.push({
            location: waypoint,
            vehicle_name: vehicle_name,
            status: vehicle_status,
            has_active_ticket: 'false',
            hash: vehicle_hash,
          });
          if (index === 1) {
            waypoints.push(waypoint);
          }
        }
      });
      let routePlaces = [];
      //if a start location has been defined, set this as the first route location on the route map
      if (this.state.selectedStartLocation) {
        routePlaces.push({
          lat: this.state.selectedStartLocation.lat,
          lng: this.state.selectedStartLocation.lng,
        });
      }
      this.props.routes[0].waypoints.forEach((route, index) => {
        if (index > 0) {
          routePlaces.push({
            lat: route.lat,
            lng: route.lng,
          });
        }
      });
      this.setState({
        loaded: true,
        showZoneMap: false,
        showRouteMap: true,
        hasCreateRouteMode: true,
        hasMapEditMode: false,
        mapCenter: waypoints[0],
        routePlaces: routePlaces,
        preRoutedVehicles: routeWaypoints,
        mapPlaces: routeWaypoints,
        active_route: route_id,
      });
    }
  };

  onClickCancelSelectStartLocation = () => {
    this.setState({
      isPickingRouteLocation: false,
      isScooterClickable: true,
      hasEditMode: true,
      showParkingSpotCreator: false,
    });
  };

  onClickCancelSelectParkingSpotLocation = () => {
    this.setState({
      showParkingSpotCreator: false,
      showScooterToggle: true,
      showZoneMap: true,
      parkingLatitude: '',
      parkingLongitude: '',
      parkingSubmitDisabled: true,
    });
  };

  onClickAssignBtn = async () => {
    this.setState({
      loaded: false,
    });
    if (this.state.selectedOps === undefined) {
      alert('Please select an ops user to assign to route');
    } else {
      if (this.state.selectedRouteType === '1') {
        //replace route
        if (this.state.assignedFieldRouteId) {
          await this.props.actions.deleteFieldRoute(this.state.assignedFieldRouteId);
        }
        const params = {
          route_id: this.props.routes[0].route_id[0],
          zone_id: this.state.selectedZone.id,
          excluded_vehicles: this.state.excludedRouteVehicles,
        };
        await this.props.actions.createFieldRoute(params);
        const ticket_params = {
          field_route_id: this.props.field_routes.selected_route[0],
          ops_users: [this.state.selectedOps],
          excluded_vehicles: this.state.excludedRouteVehicles,
        };
        await this.props.actions.createFieldTickets(ticket_params);
        this.setState({
          loaded: true,
        });
        alert('Route successfully created');
        this.reset();
      } else if (this.state.selectedRouteType === '2') {
        // create tickets and append to route
        const params = {
          field_route_id: this.state.assignedFieldRouteId,
          route_id: this.props.routes[0].route_id[0],
          ops_users: this.state.selectedOps,
        };
        await this.props.actions.createFieldTickets(params);
        this.setState({
          loaded: true,
        });
        alert('Route successfully appended');
        this.reset();
      }
    }
  };

  onClickScheduleBtn = async () => {
    this.setState({
      showScheduleInputs: true,
    });

    if (this.state.selectedOps === undefined) {
      alert('Please select an ops user to assign to route');
    } else {
      const isValid = true; //await this.validateRouteDetails();
      this.setState({
        loaded: true,
      });
      if (this.state.statuses.pickup_requested) {
        await this.setState((prevState) => ({
          statusesArr: [...prevState.statusesArr, 'pickup_requested'],
        }));
      }
      if (this.state.statuses.damaged) {
        await this.setState((prevState) => ({
          statusesArr: [...prevState.statusesArr, 'damaged'],
        }));
      }
      if (this.state.statuses.low_battery) {
        await this.setState((prevState) => ({
          statusesArr: [...prevState.statusesArr, 'low_battery'],
        }));
      }
      if (this.state.statuses.reserved) {
        await this.setState((prevState) => ({
          statusesArr: [...prevState.statusesArr, 'reserved'],
        }));
      }
      if (this.state.statuses.missing) {
        await this.setState((prevState) => ({
          statusesArr: [...prevState.statusesArr, 'missing'],
        }));
      }
      if (this.state.statuses.inaccessible) {
        await this.setState((prevState) => ({
          statusesArr: [...prevState.statusesArr, 'inaccessible'],
        }));
      }
      if (this.state.statuses.emergency_pickup) {
        await this.setState((prevState) => ({
          statusesArr: [...prevState.statusesArr, 'emergency_pickup'],
        }));
      }
      if (isValid) {
        this.setState({
          loaded: false,
        });
        if (this.state.hasScheduledRoute) {
          await this.props.actions.deleteScheduledRoute(this.state.assignedScheduledRouteId);
        }
        /* todo- this will only delete if replaced route is selected and from a routed schedule popup
        let selectedScooters;
        if (this.props.hasScheduledRoute && this.props.scheduleType !== 'routed')
            selectedScooters = this.state.selectedRouteScooters.map(scooter => {
            return scooter.name;
          })
        */
        const vehicleLimit = this.state.vehicleLimit
          ? this.state.vehicleLimit
          : ROUTE_VEHICLE_LIMIT;
        const time_zone_name = this.state.selectedZone.time_zone_name;
        moment.tz.setDefault(time_zone_name);
        const scheduled_date = moment(this.state.schedule_date)
          .tz(time_zone_name)
          .tz('GMT')
          .subtract(10, 'minutes')
          .format('MMM DD YYYY HH:mm:ss');
        const warehouseId = this.props.warehouse.id;
        // const shift_start = moment().tz('America/Los_Angeles')
        // .format('MMM DD YYYY HH:mm:ss');
        const shift_end = moment(scheduled_date)
          .add(23, 'hours')
          .tz('America/Los_Angeles')
          .format('MMM DD YYYY HH:mm:ss');
        let selectedBatteryPct = 0;
        let statuses = this.state.statusesArr;
        let staleness_days = 0;
        if (this.state.isStaleSelected) {
          staleness_days = this.state.selectedStalenessOption;
          statuses.push('available');
        }
        if (this.state.isBatteryPctSelected === true) {
          selectedBatteryPct = this.state.selectedBatteryPct;
        } else {
          selectedBatteryPct = 0;
        }
        const params = {
          zone_id: this.state.selectedZone.id,
          warehouse_id: warehouseId,
          truck_capacity: vehicleLimit, // todo add to append/routes case - selectedScooters.length,
          status: '',
          shift_start_time: scheduled_date,
          shift_end_time: shift_end,
          max_time: 23,
          damaged_dwell_time: DAMAGED_DWELL_TIME,
          pickup_dwell_time: PICKUP_REQUESTED_DWELL_TIME,
          staleness_days: staleness_days,
          created_by: 0,
          assigned_to: this.state.selectedOps,
          scheduled_date: scheduled_date,
          selected_battery_pct: selectedBatteryPct,
          //vehicle_names:  //JSON.stringify(selectedScooters),  todo- add this in append/routed case
        };
        await this.props.actions.createScheduledRoute(params);
        this.setState({
          loaded: true,
        });
        alert('Route successfully scheduled');
        this.reset();
      }
    }
  };

  handleMaxScootersChange = (val) => {
    const name = 'vehicleLimit';
    this.setState({
      [name]: val,
    });
  };

  handleScheduleRouteChange = (val) => {
    this.setState({
      schedule_date: val,
    });
  };

  handleSubzoneChange = (event) => {
    let selected_subzones = this.state.selectedSubzones;
    let check = event.target.checked;
    let checked_subzone = event.target.value;
    if (check) {
      this.setState({
        selectedSubzones: [...this.state.selectedSubzones, checked_subzone],
      });
    } else {
      var index = selected_subzones.indexOf(checked_subzone);
      if (index > -1) {
        selected_subzones.splice(index, 1);
        this.setState({
          selectedSubzones: selected_subzones,
        });
      }
    }
    setTimeout(() => {}, 2000);
  };

  handleRoutingTypeSelection = (event) => {
    const newVal = event.target.value;
    this.setState({
      selectedRouteType: newVal,
    });
  };

  handleScheduledRoutingTypeSelection = (event) => {
    const newVal = event.target.value;
    this.setState({
      selectedScheduledRouteType: newVal,
    });
  };

  handleCancel = async () => {
    this.setState({
      visible: false,
      showRouteFilterModal: false,
      showScheduleRouteModal: false,
      showGeofenceModal: false,
      showParkingSpotModal: false,
      showAssignRouteModal: false,
      hasAssignedRoute: false,
      selectedOps: undefined,
      assignedRouteId: undefined,
      polygon: undefined,
      geofenceName: '',
      geofenceNotes: undefined,
      geofenceHasThrottleCut: undefined,
      geofenceCanPark: undefined,
      geofenceCanStartRide: undefined,
      geofenceHasPickupRequested: undefined,
      geofenceSpeedLimit: undefined,
      geofenceParkingSurcharge: undefined,
      selectedRestrictedArea: undefined,
      geofenceRidingAllowed: undefined,
    });
  };

  onClickUndoGeofence = () => {
    this.setState({
      hasNewDrawingManager: true,
    });
  };

  onClickUndoWarehouse = () => {
    this.setState({
      hasNewDrawingManager: true,
    });
  };

  onClickConfirmNewWarehouseArea = () => {
    this.setState({
      showCreateWarehouseAreaModal: true,
      showWarehouseCreator: false,
      loaded: true,
    });
  };
  onClickSaveNewWarehouseArea = async () => {
    this.setState({
      loaded: false,
    });
    let polygonCoordsArray = [];
    let coords = this.state.polygon.getPath().getArray();
    for (let i = 0; i < coords.length; i++) {
      const locationArray = [coords[i].lat(), coords[i].lng()];
      polygonCoordsArray.push(locationArray);
    }
    const params = {
      geofence: polygonCoordsArray,
      name: this.state.name,
    };
    await this.props.actions
      .createWarehouse(this.state.selectedZone.id, params)
      //todo replace with success
      .then(() => {
        alert(`${params.name} successfully added!`);
      })
      .catch(() => {
        alert(`There was a problem adding ${params.name}`);
      });
    await this.reset();
    this.setState({
      hasNewDrawingManager: true,
      loaded: true,
    });
  };

  onClickSaveGeofence = async () => {
    this.setState({
      loaded: false,
    });
    let polygonCoordsArray = [];
    let coords = this.state.polygon.getPath().getArray();
    for (let i = 0; i < coords.length; i++) {
      const locationArray = [coords[i].lat(), coords[i].lng()];
      polygonCoordsArray.push(locationArray);
    }
    const params = {
      geofence: polygonCoordsArray,
    };
    await this.props.actions
      .createRestrictedArea(this.state.selectedZone.id, params)
      //todo replace with success
      .then(() => {
        alert('Geofence successfully created');
      })
      .catch(() => {
        alert('There was a problem creating the geofence');
      });
    await this.reset();
    this.setState({
      hasNewDrawingManager: true,
      loaded: true,
    });
  };

  onClickExitCreateGeofence = () => {
    this.setState({
      showGeofenceCreator: false,
      showZoneMap: true,
      showScooterToggle: true,
    });
  };

  onClickExitCreateWarehouse = () => {
    this.setState({
      showWarehouseCreator: false,
      showZoneMap: true,
      showScooterToggle: true,
    });
  };

  handleSelectedBatteryPctChange = (event) => {
    this.setState({
      selectedBatteryPct: event.target.value,
    });
  };

  // To do - combine these next two functions to one and handle the polygon creation in the zoneMaps component like we do with rectangle areas
  getPolygonVehicles = async (polygon) => {
    this.setState({
      loaded: false,
      // showZoneMap: false,
    });
    let statusArray = [];
    Object.entries(this.state.statuses).forEach(([key, value]) => {
      if (value) {
        statusArray.push(key);
      }
    });
    let first_location = '';
    let newPoly = polygon.map((location, index) => {
      let new_location = location.split(',').reverse().join(' ').trim();
      if (new_location.split(' ').length > 2) {
        new_location = new_location.split(' ');
        new_location.splice(1, 1);
        new_location = new_location.join(' ');
      }
      if (index === 0) {
        first_location = new_location;
      }
      return new_location;
    });
    newPoly = newPoly + ',' + first_location;
    const params = {
      polygon_points: newPoly,
      statuses: this.state.segmentStatuses,
    };
    await this.props.actions.getVehiclesInPolygon(this.state.selectedZone.id, params);
    let newSelectedScooters = this.props.selectedVehicles.filter((vehicle) =>
      this.doesShow(vehicle, this.state.selectedStatusSegment, this.state.selectedVehicleSegment)
    );
    if (this.state.selectedRouteScooters.length > 0) {
      const newRoutedScooters = this.state.selectedRouteScooters.concat(newSelectedScooters);
      this.setState({
        selectedRouteScooters: newRoutedScooters,
        loaded: true,
        drawingControl: false,
      });
    } else {
      this.setState({
        selectedRouteScooters: newSelectedScooters,
        loaded: true,
        drawingControl: false,
      });
    }
    this.setVehicleCounts(this.state.selectedRouteScooters);
  };

  showMinimumRouteAlert = () => {
    alert('You must have at least 2 destinations on a route.');
  };

  handleDeleteStatusFromRoute = async (status) => {
    let routedVehicles = this.state.mapPlaces;
    let excludedRouteVehicles = this.state.excludedRouteVehicles;
    routedVehicles = routedVehicles.filter((scooter) => {
      if (scooter.status !== status) {
        return true;
      } else {
        excludedRouteVehicles.push(scooter.vehicle_name);
        return false;
      }
    });
    if (routedVehicles.length < 2) {
      this.showMinimumRouteAlert();
    } else {
      const routePlaces = routedVehicles.map((vehicle) => {
        return vehicle.location;
      });
      this.setState(
        {
          mapPlaces: routedVehicles,
          routePlaces: routePlaces,
          excludedRouteVehicles: excludedRouteVehicles,
        },
        () => {
          this.setVehicleCounts(routedVehicles);
        }
      );
    }
  };

  deleteVehicleFromRouteClick = async (vehicle) => {
    let routedVehicles = this.state.mapPlaces;
    if (routedVehicles.length > 2) {
      routedVehicles = routedVehicles.filter((scooter) => scooter.hash !== vehicle.hash);
      let routePlaces = this.state.routePlaces;
      routePlaces = routePlaces.filter((location) => {
        if (location.lat !== vehicle.location.lat && location.lng !== vehicle.location.lng) {
          return true;
        } else {
          return false;
        }
      });
      this.setState({
        mapPlaces: routedVehicles,
        routePlaces: routePlaces,
        excludedRouteVehicles: [...this.state.excludedRouteVehicles, vehicle.vehicle_name],
      });
      this.setVehicleCounts(routedVehicles);
    } else {
      this.showMinimumRouteAlert();
    }
  };

  handlePolygonSegmentClick = (value, index) => {
    this.setState({
      activePolygonSegmentIndex: index,
      selectedPolygonSegment: value,
    });
  };

  onChangeGeofenceInputValues = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  onChangeParkingSpotInputValues = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  onChangeGeofenceSegmentValues = (event) => {
    const { name } = event.target;
    this.setState({ [name]: event.target.checked });
  };

  onChangeParkingSpotSegmentValues = (event) => {
    const { name } = event.target;
    this.setState({ [name]: event.target.checked });
  };

  getRectangleVehicles = async (polygon_points) => {
    this.setState({
      loaded: false,
    });
    const params = {
      polygon_points,
      statuses: this.state.segmentStatuses,
    };
    await this.props.actions.getVehiclesInArea(this.state.selectedZone.id, params);
    this.setState({
      selectedRouteScooters: this.props.selectedVehicles,
      loaded: true,
      showZoneMap: true,
    });
    this.setVehicleCounts(this.props.selectedVehicles);
  };

  getGeofenceModalTitle = () => {
    const selectedRestrictedAreas = this.state.selectedRestrictedAreas || [];
    const currentPage = this.state.currentPage;
    //Hide geofence popup paging buttons if there is only 1 geofence
    const title =
      selectedRestrictedAreas.length === 1
        ? 'Selected Geofence'
        : `${currentPage} of ${selectedRestrictedAreas.length} Selected Geofences`;
    const prevHidden = selectedRestrictedAreas.length === 1;
    const nextHidden = selectedRestrictedAreas.length === 1;
    const prevClass = currentPage === 1 ? 'geofence-back-disabled' : 'geofence-back';
    const nextClass =
      currentPage === selectedRestrictedAreas.length ? 'geofence-next-disabled' : 'geofence-next';
    return (
      <Fragment>
        <span>{title}</span>
        <img
          src={iconArrowPrevWhite}
          alt="icon-back"
          className={prevClass}
          onClick={this.backPageClick}
          hidden={prevHidden}
        />
        <img
          src={iconArrowNextWhite}
          alt="icon-available"
          className={nextClass}
          onClick={this.forwardPageClick}
          hidden={nextHidden}
        />
      </Fragment>
    );
  };

  onChangeParkingSpotForm = (event) => {
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () =>
        this.setState({
          parkingSubmitDisabled:
            isNaN(this.state.parkingLatitude) ||
            isNaN(this.state.parkingLongitude) ||
            this.state.parkingLatitude.length === 0 ||
            this.state.parkingLongitude.length === 0,
        })
    );
  };

  onSubmitParkingSpotForm = (event) => {
    event.preventDefault();
    this.pickParkingSpotLocationClick(this.state.parkingLatitude, this.state.parkingLongitude);
  };

  downloadParkingCapacityCSV = (event, done) => {
    parkingSpotCapacities(this.state.selectedZone.id)
      .then(({ parking_spots }) => {
        // Only care about parking spots that have an actual capacity limit
        this.setState({ parkingSpotCapacities: parking_spots.filter((spot) => spot.capacity) });
        done(true);
      })
      .catch(() => done(false));
  };

  onZoom = (mapZoom) => this.setState({ mapZoom });

  onDrag = (location) => this.setState({ mapCenter: location });

  render() {
    const subzones = this.props.subzones;
    const mapBorderStyle =
      this.state.hasMapEditMode || this.state.showGeofenceCreator ? 'dotted' : 'none';
    const routeStartLocationText = <span className="">Start From: {this.getLocationLabel()}</span>;
    const geofenceModalStyle =
      'yellow-modal large-white-x red-header-background white-font previous next';
    const new_route_btn_opacity = this.state.showScooters ? 1.0 : 0.3;
    let cancelButtonStyle = {
      opacity: 0.33,
      background: '#1890ff',
      borderColor: '#1890ff',
      color: 'white',
      right: '30px',
    };
    let scheduleButtonStyle = {
      opacity: 1.0,
      width: '170px',
      right: '25px',
      background: 'rgb(24, 144, 255)',
      borderColor: 'rgb(24, 144, 255)',
      color: 'white',
    };
    let applyButtonStyle = {
      opacity: 1.0,
      width: '100px',
      right: '20px',
      background: 'rgb(24, 144, 255)',
      borderColor: 'rgb(24, 144, 255)',
      color: 'white',
    };
    return (
      <AuthenticatedPage className="edit_vehicle_container">
        <div className="page-title">Manage Market</div>
        {!this.state.hasCreateRouteMode && (
          <div className="zone-picker">
            <h3>Select Zone</h3>
            <DropdownList
              className="vehicles_container__select select-zone"
              data={this.state.activeZones}
              value={this.state.selectedZone}
              valueField="id"
              textField="name"
              onChange={this.handleZoneChange}
              placeholder="Select a Zone"
            />
          </div>
        )}
        {this.state.showRouteFilterModal && (
          <Modal
            title={<ModalTitle title="Add Filter" style={{ backgroundColor: 'red' }} />}
            visible={this.state.showRouteFilterModal}
            width={570}
            className={geofenceModalStyle}
            onCancel={this.handleCancel}
            footer={[
              <Button key="cancel" onClick={this.handleCancel} style={cancelButtonStyle}>
                Cancel
              </Button>,
              <Button
                key="schedule"
                onClick={this.onClickScheduleFilteredRoute}
                style={scheduleButtonStyle}>
                Schedule For Later
              </Button>,
              <Button key="apply" onClick={this.onClickApplyFilter} style={applyButtonStyle}>
                Apply
              </Button>,
            ]}>
            <RouteFiltersModal
              vehicles={this.props.vehicles}
              statuses={this.state.statuses}
              isBatteryPctSelected={this.state.isBatteryPctSelected}
              selectedBatteryPct={this.state.selectedBatteryPct}
              isStaleSelected={this.state.isStaleSelected}
              selectedStalenessOption={this.state.selectedStalenessOption}
              subzones={subzones}
              handleBatteryPctChange={this.handleBatteryPctChange}
              handleStatusesChange={this.handleStatusesChange}
              handleSelectedBatteryPctChange={this.handleSelectedBatteryPctChange}
              handleStalenessOptionChange={this.handleStalenessOptionChange}
              handleStalenessChange={this.handleStalenessChange}
              handleSubzoneChange={this.handleSubzoneChange}
              handleMaxScootersChange={this.handleMaxScootersChange}
              handleScheduleClick={this.handleScheduleClick}
              showScheduleInputs={this.state.showScheduleInputs}
            />
          </Modal>
        )}
        {this.state.showFirstControlRow && !this.state.hasCreateRouteMode && (
          <div className="map_toggles">
            <div className="map-control">
              <label>Parking Spots</label>
              <input
                type="checkbox"
                id="parking_toggle"
                checked={this.state.showParkingSpots}
                onChange={this.handleShowParkingChange}
              />
            </div>
            <div className="map-control">
              <label>Polygons</label>
              <input
                type="checkbox"
                id="polygon_toggle"
                checked={this.state.showPolygons}
                onChange={this.handleShowPolygonsChange}
              />
              <SegmentControl
                name="polygons"
                defaultIndex={0}
                activeIndex={this.state.activePolygonSegmentIndex}
                onSegmentClick={this.handlePolygonSegmentClick}
                segments={[
                  {
                    label: 'GEOFENCES',
                    value: 'geofence',
                  },
                  {
                    label: 'SUBZONES',
                    value: 'subzone',
                  },
                ]}
              />
            </div>
            <div className="map-control">
              <label>Operators</label>
              <input
                type="checkbox"
                id="operators_toggle"
                checked={this.state.showOperators}
                onChange={this.handleShowOpsChange}
              />
            </div>
            <div className="map-control">
              <label>Live Mode</label>
              <input
                type="checkbox"
                id="live_toggle"
                onChange={this.handleShowLiveChange}
                className="toggle"
              />
            </div>
            <div style={{ flexGrow: 1 }} />
            <div className="create-new-dropdown-container">
              <DropdownList
                className="create-new-dropdown"
                data={[
                  { label: 'Warehouse', value: 'warehouse' },
                  { label: 'Parking Spot', value: 'parking_spot' },
                  { label: 'Geofence', value: 'geofence' },
                  { label: 'Route', value: 'route' },
                ]}
                value={null}
                valueField="value"
                textField="label"
                onChange={this.createNewItem}
                placeholder="Create New"
              />
            </div>
          </div>
        )}
        {this.state.showScooterToggle &&
          !this.state.hasCreateRouteMode &&
          !this.state.hasMapEditMode && (
            <div className="map_toggles">
              <div className="map-control">
                <label>Fleet</label>
                <input
                  type="checkbox"
                  checked={this.state.showScooters}
                  onChange={this.handleShowScooterChange}
                />
              </div>
              <div className="map-control">
                <label>Uncorralled vehicles only</label>
                <input
                  type="checkbox"
                  checked={this.state.showUncorralledOnly}
                  onChange={this.handleUncorralledChange}
                />
              </div>
              <div className="map-control">
                <label>Status</label>
                <DropdownList
                  className="status-dropdown"
                  data={[
                    { label: 'ALL', value: 'all' },
                    { label: 'AVAILABLE', value: 'available' },
                    { label: 'PICKUP', value: 'pickup' },
                    { label: 'COLLECTED', value: 'collected' },
                    { label: 'ISSUES', value: 'issues' },
                    { label: 'RIDING', value: 'riding' },
                  ]}
                  value={this.state.activeStatusValue}
                  valueField="value"
                  textField="label"
                  onChange={this.handleStatusSegmentChange}
                  placeholder="Select a Status"
                />
              </div>
              <div className="map-control">
                <label>Vehicle Type</label>
                <DropdownList
                  className="status-dropdown"
                  data={[
                    { label: 'ALL', value: 'all' },
                    { label: 'DD ONLY', value: 'dd' },
                    { label: 'S1 ONLY', value: 's1' },
                  ]}
                  value={this.state.activeScooterTypeValue}
                  valueField="value"
                  textField="label"
                  onChange={this.handleScooterTypeSegmentChange}
                  placeholder="Select a Vehicle Type"
                />
              </div>
            </div>
          )}
        {this.state.showParkingSpotCreator && (
          <Fragment>
            <p style={{ marginTop: '20px' }}>
              Click the location on the map where you would like to place the parking spot, OR enter
              the latitude and longitude and press &lsquo;submit&rsquo;.
            </p>
            <div className="select-start-container">
              <div className="create-routes-container-elements">
                <div className="create-parking-spot-header">
                  <form
                    className="create-parking-spot-form"
                    onSubmit={this.onSubmitParkingSpotForm}>
                    <label htmlFor="parkingLatitude">Latitude</label>
                    <input
                      name="parkingLatitude"
                      value={this.state.parkingLatitude}
                      onChange={this.onChangeParkingSpotForm}
                    />
                    <label htmlFor="parkingLongitude">Longitude</label>
                    <input
                      name="parkingLongitude"
                      value={this.state.parkingLongitude}
                      onChange={this.onChangeParkingSpotForm}
                    />
                    <button type="submit" disabled={this.state.parkingSubmitDisabled}>
                      SUBMIT
                    </button>
                    <button onClick={this.onClickCancelSelectParkingSpotLocation}>CANCEL</button>
                  </form>
                </div>
              </div>
            </div>
          </Fragment>
        )}
        {this.state.showWarehouseCreator && (
          <Fragment>
            <div className="create-routes-container">
              <div className="create-routes-container-elements">
                <div className="create-route-label">Add a Warehouse</div>
                <div
                  className="undo-geofence-btn"
                  onClick={this.onClickUndoWarehouse}
                  style={{ opacity: new_route_btn_opacity }}>
                  {' '}
                  UNDO
                </div>
                <div
                  className="save-geofence-btn"
                  onClick={this.onClickConfirmNewWarehouseArea}
                  style={{ opacity: new_route_btn_opacity }}>
                  {' '}
                  SAVE
                </div>
                <div
                  className="exit-route-btn"
                  onClick={this.onClickExitCreateWarehouse}
                  style={{ opacity: new_route_btn_opacity }}>
                  {' '}
                  X EXIT
                </div>
              </div>
            </div>
          </Fragment>
        )}
        {this.state.showGeofenceCreator && (
          <Fragment>
            <div className="create-routes-container">
              <div className="create-routes-container-elements">
                <div className="create-route-label">CREATE GEOFENCE</div>
                <div
                  className="undo-geofence-btn"
                  onClick={this.onClickUndoGeofence}
                  style={{ opacity: new_route_btn_opacity }}>
                  {' '}
                  UNDO
                </div>
                <div
                  className="save-geofence-btn"
                  onClick={this.onClickSaveGeofence}
                  style={{ opacity: new_route_btn_opacity }}>
                  {' '}
                  SAVE
                </div>
                <div
                  className="exit-route-btn"
                  onClick={this.onClickExitCreateGeofence}
                  style={{ opacity: new_route_btn_opacity }}>
                  {' '}
                  X EXIT
                </div>
              </div>
            </div>
          </Fragment>
        )}
        {this.state.hasMapEditMode &&
          !this.state.hasCreateRouteMode &&
          !this.state.isPickingRouteLocation && (
            <Fragment>
              <div className="create-routes-container">
                <div className="create-routes-container-elements">
                  <div className="create-route-label">CREATE ROUTE</div>
                  <div className="start-location-label">{routeStartLocationText}</div>
                  <div
                    className="start-location-btn"
                    onClick={this.onClickPickRouteLocation}
                    style={{ opacity: new_route_btn_opacity }}>
                    {' '}
                    + START LOCATION
                  </div>
                  <div
                    className="add-filter-btn"
                    onClick={this.onClickFilterRoute}
                    style={{ opacity: new_route_btn_opacity }}>
                    {' '}
                    + ADD/SCHEDULE FILTER
                  </div>
                  <div
                    className="preview-route-btn"
                    onClick={this.onClickGetRoute}
                    style={{ opacity: new_route_btn_opacity }}>
                    {' '}
                    PREVIEW ROUTE
                  </div>
                  <div
                    className="exit-route-btn"
                    onClick={this.onClickExitRoute}
                    style={{ opacity: new_route_btn_opacity }}>
                    {' '}
                    X EXIT
                  </div>
                </div>
              </div>
            </Fragment>
          )}
        {this.state.showFirstControlRow && this.state.isPickingRouteLocation && (
          <Fragment>
            <div className="select-start-container">
              <div className="create-routes-container-elements">
                <div className="start-location-title">CHOOSE START LOCATION</div>
                <div className="exit-route-btn" onClick={this.onClickCancelSelectStartLocation}>
                  {' '}
                  CANCEL
                </div>
              </div>
            </div>
          </Fragment>
        )}
        {this.state.showFirstControlRow &&
          !this.state.hasCreateRouteMode &&
          !this.state.hasMapEditMode &&
          !this.state.showGeofenceCreator &&
          !this.state.showParkingSpotCreator && <StatusCounts vehicles={this.state.mapVehicles} />}
        {this.state.hasCreateRouteMode && (
          <Fragment>
            <div className="create-route-container">
              <div className="create-routes-container-elements">
                <div className="assign-route-btn" onClick={this.onClickAssignNow}>
                  {' '}
                  ASSIGN NOW
                </div>
                <div className="schedule-route-btn" onClick={this.onClickScheduleManualRoute}>
                  {' '}
                  + SCHEDULE FOR LATER
                </div>
                <div className="exit-route-btn" onClick={this.onClickCancelRoute}>
                  {' '}
                  BACK
                </div>
              </div>
            </div>
          </Fragment>
        )}
        {(this.state.hasCreateRouteMode ||
          this.state.hasMapEditMode ||
          this.state.isPickingRouteLocation) && (
          <RoutedStatusCounts
            handleDeleteStatusFromRoute={this.handleDeleteStatusFromRoute}
            hasCreateRouteMode={this.state.hasCreateRouteMode}
            routedVehicleCounts={this.state.routedVehicleCounts}
          />
        )}
        {this.state.showGeofenceCreator && (
          <Fragment>
            <div className="selected-counts-container">
              <div className="selected-counts-labels" />
            </div>
          </Fragment>
        )}
        {this.state.showParkingSpots && !this.state.hasMapEditMode && this.state.showZoneMap && (
          <ZoneMapLegend />
        )}
        {this.state.showZoneMap && (
          <ZoneMap
            addScooterToRouteClick={this.addScooterToRouteClick}
            center={this.state.mapCenter}
            containerElement={
              <div
                style={{
                  height: '800px',
                  width: '100%',
                  borderWidth: 'thick',
                  borderColor: 'red',
                  borderStyle: mapBorderStyle,
                }}
              />
            }
            deploymentAreas={this.state.mapDeploymentAreas}
            deploymentSpots={this.props.deployment_spots.deploymentSpots}
            drawingControl={this.state.drawingControl}
            externalOperator={this.props.admin.external_operator}
            getPolygonVehicles={this.getPolygonVehicles}
            getRectangleVehicles={this.getRectangleVehicles}
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAJIYWGel69VKdzFDmRV3fYZay_pO36GOk&v=3.exp&libraries=geometry,drawing,places"
            hasEditMode={this.state.hasMapEditMode}
            hasSetLocation={this.state.hasSetLocation}
            infoWindowClick={this.infoWindowClick}
            isPickingRouteLocation={this.state.isPickingRouteLocation}
            isScooterClickable={this.state.isScooterClickable}
            loadingElement={<div style={{ height: '100%' }} />}
            mapElement={<div style={{ height: '100%' }} />}
            mapOpsLocations={this.props.mapOpsLocations}
            mapVehicles={this.state.mapVehicles}
            mapZoneBoundary={this.state.mapZoneBoundary}
            onZoom={this.onZoom}
            onDrag={this.onDrag}
            openMapClick={this.openMapClick}
            parkingSpots={this.state.mapParkingSpots}
            pickRouteLocationClick={this.pickRouteLocationClick}
            removeRoutedScooterClick={this.removeRoutedScooterClick}
            restrictedAreaClick={this.restrictedAreaClick}
            restrictedAreas={this.props.features.restrictedAreas}
            routedScooters={this.state.routedScooters}
            scooterClick={this.scooterClick}
            selectedParkingSpot={this.state.selectedParkingSpot}
            selectedPolygonSegment={this.state.selectedPolygonSegment}
            selectedRestrictedArea={this.state.selectedRestrictedArea}
            selectedRouteScooters={this.state.selectedRouteScooters}
            selectedScooter={this.state.selectedScooter}
            selectedScooterBatteryPct={this.state.selectedScooterBatteryPct}
            selectedScooterName={this.state.selectedScooterName}
            selectedStatusSegment={this.state.selectedStatusSegment}
            selectedSubzone={this.state.selectedSubzone}
            selectedVehicleSegment={this.state.selectedVehicleSegment}
            showDrawingManager={this.state.showDrawingManager}
            showOps={this.state.showOperators}
            showParkingSpots={this.state.showParkingSpots}
            showPolygons={this.state.showPolygons}
            showUncorralledOnly={this.state.showUncorralledOnly}
            showScooters={this.state.showScooters}
            spotClick={this.spotClick}
            startRouteLocation={this.state.selectedStartLocation}
            subzoneClick={this.subzoneClick}
            subzones={this.state.subzones}
            vehicles={this.state.vehicles}
            zoom={this.state.mapZoom}
          />
        )}
        {this.state.showRouteMap && (
          <Fragment>
            <div
              style={{
                width: '100%',
                borderColor: 'red',
              }}>
              <RouteMap
                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAJIYWGel69VKdzFDmRV3fYZay_pO36GOk&v=3.exp&libraries=geometry,drawing,places"
                loadingElement={<div style={{ height: '100%' }} />}
                containerElement={<div style={{ height: '600px' }} />}
                mapElement={<div style={{ height: '100%' }} />}
                center={this.state.mapCenter}
                zoom={this.state.mapZoom}
                onZoom={this.onZoom}
                onDrag={this.onDrag}
                routePlaces={this.state.routePlaces}
                places={this.state.mapPlaces}
                restrictedAreas={this.state.mapRestrictedAreas}
                mapClick={this.mapClick}
                isClickable="false"
                startRouteLocation={this.state.selectedStartLocation}
                deleteVehicleClick={this.deleteVehicleFromRouteClick}
              />
            </div>
          </Fragment>
        )}
        {this.state.showParkingSpotCreator && (
          <Fragment>
            <div
              style={{
                width: '100%',
                borderColor: '#0099E0',
                borderStyle: 'dotted',
              }}>
              <AddItemMap
                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAJIYWGel69VKdzFDmRV3fYZay_pO36GOk&v=3.exp&libraries=geometry,drawing,places"
                loadingElement={<div style={{ height: '100%' }} />}
                containerElement={<div style={{ height: '600px' }} />}
                mapElement={<div style={{ height: '100%' }} />}
                center={this.state.mapCenter}
                zoom={this.state.mapZoom}
                onZoom={this.onZoom}
                onDrag={this.onDrag}
                showParkingSpots={this.state.showParkingSpots}
                showParkingSpotCreator={this.state.showParkingSpotCreator}
                parkingSpots={this.state.mapParkingSpots}
                restrictedAreas={this.props.features.restrictedAreas.filter(
                  (ra) => ra.riding_allowed && ra.end_ride_allowed
                )}
                pickParkingSpotLocationClick={this.pickParkingSpotLocationClick}
              />
            </div>
          </Fragment>
        )}
        {this.state.showGeofenceCreator && (
          <Fragment>
            <div
              style={{
                width: '100%',
                borderColor: '#0099E0',
                borderStyle: 'dotted',
              }}>
              <AddItemMap
                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAJIYWGel69VKdzFDmRV3fYZay_pO36GOk&v=3.exp&libraries=geometry,drawing,places"
                loadingElement={<div style={{ height: '100%' }} />}
                containerElement={<div style={{ height: '600px' }} />}
                mapElement={<div style={{ height: '100%' }} />}
                center={this.state.mapCenter}
                zoom={this.state.mapZoom}
                onZoom={this.onZoom}
                onDrag={this.onDrag}
                hideDrawingManager={this.hideDrawingManager}
                hasDrawingManager={this.state.hasDrawingManager}
                hasNewDrawingManager={this.state.hasNewDrawingManager}
                setPolygon={this.setPolygon}
                polygon={this.state.polygon}
                restrictedAreasGrey={this.props.features.restrictedAreas.filter(
                  (ra) => ra.riding_allowed && ra.end_ride_allowed
                )}
              />
            </div>
          </Fragment>
        )}
        <DeploymentSpotCard
          selectedDeploymentSpot={this.state.selectedDeploymentSpot}
          visible={this.state.showDeploymentSpotCard}
        />
        <Modal
          title={<ModalTitle title="Assign Route" />}
          visible={this.state.showAssignRouteModal}
          onOk={this.onClickAssignBtn}
          onCancel={this.handleCancel}
          width={570}
          okText="Assign Route"
          cancelText="Cancel">
          <AssignRouteModal
            handleAssignRouteChange={this.handleAssignRouteChange}
            opsUsers={this.props.opsUsers}
            handleChangeOps={this.handleChangeOps}
            selectedOps={this.state.selectedOps}
            hasAssignedRoute={this.state.hasAssignedRoute}
            handleRoutingTypeSelection={this.handleRoutingTypeSelection}
            selectedRouteType={this.state.selectedRouteType}
          />
        </Modal>
        <Modal
          title={<ModalTitle title="Schedule Route" style={{ backgroundColor: 'red' }} />}
          visible={this.state.showScheduleRouteModal}
          onOk={this.onClickScheduleBtn}
          onCancel={this.handleCancel}
          width={570}
          okText="Schedule Route"
          cancelText="Cancel">
          <ScheduleRouteModal
            handleScheduleRouteChange={this.handleScheduleRouteChange}
            opsUsers={this.props.opsUsers}
            handleChangeOps={this.handleChangeOps}
            selectedOps={this.state.selectedOps}
            hasScheduledRoute={this.state.hasScheduledRoute}
            handleScheduledRoutingTypeSelection={this.handleScheduledRoutingTypeSelection}
            scheduleType={this.state.scheduleType}
          />
        </Modal>
        <Modal
          title={<ModalTitle title="Save Your Warehouse" />}
          visible={this.state.showCreateWarehouseAreaModal}
          onCancel={this.handleCancel}
          width={570}
          footer={this.getWarehouseModalFooter()}>
          <WarehouseModal
            onChangeGeofenceInputValues={this.onChangeGeofenceInputValues}
            warehouseName={this.state.warehouseName}
	    zoneName={this.state.selectedZoneName}
          />
        </Modal>
        <Modal
          onCancel={this.handleCancel}
          className={geofenceModalStyle}
          footer={this.getWarehouseModalFooter()}>
          <GeofenceModal
            selectedRestrictedAreas={this.state.selectedRestrictedAreas}
            pageCount={this.props.selectedRestrictedAreas.length}
            currentPage={this.state.currentPage}
            onChangeGeofenceInputValues={this.onChangeGeofenceInputValues}
            onChangeGeofenceSegmentValues={this.onChangeGeofenceSegmentValues}
            geofenceName={this.state.geofenceName}
            geofenceNotes={this.state.geofenceNotes}
            geofenceHasThrottleCut={this.state.geofenceHasThrottleCut}
            geofenceCanPark={this.state.geofenceCanPark}
            geofenceCanStartRide={this.state.geofenceCanStartRide}
            geofenceHasPickupRequested={this.state.geofenceHasPickupRequested}
            geofenceSpeedLimit={this.state.geofenceSpeedLimit}
            geofenceParkingSurcharge={this.state.geofenceParkingSurcharge}
            geofenceRidingAllowed={this.state.geofenceRidingAllowed}
            geofenceShowOnMap={this.state.geofenceShowOnMap}
          />
        </Modal>
        <Modal
          title={<ModalTitle title={this.getGeofenceModalTitle()} />}
          visible={this.state.showGeofenceModal}
          width={570}
          onCancel={this.handleCancel}
          className={geofenceModalStyle}
          footer={this.getGeofenceModalFooter()}>
          <GeofenceModal
            selectedRestrictedAreas={this.state.selectedRestrictedAreas}
            pageCount={this.props.selectedRestrictedAreas.length}
            currentPage={this.state.currentPage}
            onChangeGeofenceInputValues={this.onChangeGeofenceInputValues}
            onChangeGeofenceSegmentValues={this.onChangeGeofenceSegmentValues}
            geofenceName={this.state.geofenceName}
            geofenceNotes={this.state.geofenceNotes}
            geofenceHasThrottleCut={this.state.geofenceHasThrottleCut}
            geofenceCanPark={this.state.geofenceCanPark}
            geofenceCanStartRide={this.state.geofenceCanStartRide}
            geofenceHasPickupRequested={this.state.geofenceHasPickupRequested}
            geofenceSpeedLimit={this.state.geofenceSpeedLimit}
            geofenceParkingSurcharge={this.state.geofenceParkingSurcharge}
            geofenceRidingAllowed={this.state.geofenceRidingAllowed}
            geofenceShowOnMap={this.state.geofenceShowOnMap}
          />
        </Modal>
        <Modal
          title={<ModalTitle title="Parking Spot" />}
          visible={this.state.showParkingSpotModal}
          width={570}
          onCancel={this.handleCancel}
          onOk={this.handleCancel}
          className={geofenceModalStyle}
          footer={this.getParkingSpotModalFooter()}>
          <ParkingSpotModal
            selectedParkingSpot={this.state.selectedParkingSpot}
            onChangeParkingSpotInputValues={this.onChangeParkingSpotInputValues}
            onChangeParkingSpotSegmentValues={this.onChangeParkingSpotSegmentValues}
            selectedParkingSpotNotes={this.state.selectedParkingSpotNotes}
            selectedParkingSpotCapacity={this.state.selectedParkingSpotCapacity}
            selectedParkingSpotMinimumVehicles={this.state.selectedParkingSpotMinimumVehicles}
            selectedParkingSpotShowOnMap={this.state.selectedParkingSpotShowOnMap}
          />
        </Modal>
        <Loader loaded={this.state.loaded} />
      </AuthenticatedPage>
    );
  }
}

ManageMarket.propTypes = {
  actions: PropTypes.object,
  admin: PropTypes.object,
  deployment_spots: PropTypes.object,
  features: PropTypes.object,
  field_routes: PropTypes.array,
  history: PropTypes.object,
  mapOpsLocations: PropTypes.array,
  opsUsers: PropTypes.array,
  parking_spot: PropTypes.object,
  routes: PropTypes.array,
  scheduled_routes: PropTypes.array,
  selected_zone: PropTypes.number,
  selectedRestrictedAreas: PropTypes.array,
  selectedVehicles: PropTypes.array,
  subzones: PropTypes.array,
  vehicles: PropTypes.array,
  warehouse: PropTypes.object,
  zones: PropTypes.array,
};

const mapStateToProps = (state) => {
  const {
    admin,
    field_routes,
    field_route_tickets,
    parking_spots,
    routes,
    scheduled_routes,
    zones,
    users,
    vehicles,
    warehouse: {
      warehouse: [warehouse],
    },
    zone_rides,
    vehicle_locations,
    rides,
    restricted_areas: { selectedRestrictedAreas },
    selected_zone,
    subzones: { subzones },
    ops_locations: { ops_user_locations },
  } = state;
  return {
    admin,
    field_routes,
    field_route_tickets,
    zones: zones.zones,
    features: zones.features,
    deployment_spots: zones.deployment_spots,
    parking_spot: zones.parking_spot,
    parking_spots,
    routes,
    scheduled_routes,
    opsUsers: users.ops.filter(({ is_active }) => is_active),
    vehicles: vehicles.vehicles,
    selectedVehicles: vehicles.selected,
    warehouse,
    zone_rides,
    vehicle_locations,
    rides,
    selected_zone,
    selectedRestrictedAreas,
    subzones,
    mapOpsLocations: ops_user_locations.filter((location) => {
      const momentObj = moment(location.created_date);
      const staleness = moment.duration(moment().diff(momentObj)).asMinutes();
      location.staleness = staleness;
      location.isStale = staleness > 29;
      return staleness < 60;
    }),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        createFieldTickets,
        createFieldRoute,
        createParkingSpot,
        createRestrictedArea,
        createScheduledRoute,
        deleteFieldRoute,
        deleteFieldRouteTicket,
        deleteParkingSpot,
        deleteRestrictedArea,
        deleteScheduledRoute,
        getActiveVehicleRouteTicket,
        getDeploymentSpots,
        getFeatures,
        getFieldRoutes,
        getManualRoute,
        getOpsLocations,
        getOpsUsers,
        getParkingSpot,
        getRestrictedAreasWithParams,
        getRidesInZone,
        getRoutes,
        getRouteVehicles,
        getScheduledRoutes,
        getSubzones,
        getWarehouse,
        getVehiclesInArea,
        getVehiclesInPolygon,
        getZoneVehicles,
        patchParkingSpot,
        patchRestrictedArea,
        setSelectedZone,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageMarket);
