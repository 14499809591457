import moment from 'moment-timezone';
import React, { Component, Fragment } from 'react';
import { DateTimePicker } from 'react-widgets';
import { DropdownList } from 'react-widgets';
import momentLocalizer from 'react-widgets-moment';

moment.locale('en');
momentLocalizer();

export default class ScheduleRouteModal extends Component {
  handleScheduleRouteChange = (val) => {
    this.props.handleScheduleRouteChange(val);
  };

  handleChangeOps = (val) => {
    this.props.handleChangeOps(val, 'schedule');
  };

  render() {
    return (
      <div className="modal">
        <Fragment>
          <h2>Schedule Route</h2>
          <h3> Select date and time to run route: </h3>
          <div className="date-selector-container">
            <DateTimePicker
              className="schedule_date_picker"
              defaultValue={this.props.schedule_date}
              onChange={(e) => this.handleScheduleRouteChange(e)}
            />
          </div>
          <h3>Assign to employee </h3>
          <DropdownList
            className="vehicles_container__select"
            data={this.props.opsUsers}
            value={this.props.selectedOps}
            valueField="id"
            textField="fullname"
            onChange={this.handleChangeOps}
            placeholder="Select a field ops employee"
          />
        </Fragment>
        {this.props.hasScheduledRoute && this.props.scheduleType === 'to add routed' && (
          <Fragment>
            <h3>Employee is already on a scheduled route. What do you want to do? </h3>
            <input
              type="radio"
              id="replace"
              className="routeTypeRadio"
              value="1"
              checked={this.props.selectedScheduledRouteType === '1'}
              onChange={this.handleScheduledRoutingTypeSelection}
            />
            <label htmlFor="append" className="routeTypeRadioLabel">
              Replace Scheduled Route
            </label>
            <input
              type="radio"
              id="append"
              className="routeTypeRadio"
              value="2"
              checked={this.props.selectedScheduledRouteType === '2'}
              onChange={this.handleScheduledRoutingTypeSelection}
            />
            <label htmlFor="replace" className="routeTypeRadioLabel">
              Append To Scheduled Route
            </label>
          </Fragment>
        )}
      </div>
    );
  }
}
