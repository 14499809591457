import React from 'react';

import pin_parking from '../images/pin-parking.png';
import pin_parking_empty from '../images/pin-parking-empty.png';
import pin_parking_over_capacity from '../images/pin-parking-over-capacity.png';

import './ZoneMapLegend.scss';

const ZoneMapLegend = () => {
  return (
    <div className="legend-container">
      <img src={pin_parking} />
      <span>No issues</span>
      <img src={pin_parking_empty} />
      <span>Empty</span>
      <img src={pin_parking_over_capacity} />
      <span>Over capacity</span>
    </div>
  );
};

export default ZoneMapLegend;
