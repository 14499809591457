import React, { Component, Fragment } from 'react';
import { DropdownList } from 'react-widgets';

import './index.scss';

export default class AssignRouteModal extends Component {
  handleAssignRouteChange = (val) => {
    this.props.handleAssignRouteChange(val);
  };

  handleChangeOps = (val) => {
    this.props.handleChangeOps(val, 'assign');
  };

  handleRoutingTypeSelection = (val) => {
    this.props.handleRoutingTypeSelection(val);
  };

  render() {
    return (
      <div className="modal">
        <h3>Assign to employee </h3>
        <DropdownList
          className="vehicles_container__select"
          data={this.props.opsUsers}
          value={this.props.selectedOps}
          valueField="id"
          textField="fullname"
          onChange={this.handleChangeOps}
          placeholder="Select a field ops employee"
        />
        {this.props.hasAssignedRoute && (
          <Fragment>
            <h3>Employee is already on a route. What do you want to do? </h3>
            <input
              type="radio"
              id="replace"
              className="routeTypeRadio"
              value="1"
              checked={this.props.selectedRouteType === '1'}
              onChange={this.handleRoutingTypeSelection}
            />
            <label htmlFor="append" className="routeTypeRadioLabel">
              Replace Route
            </label>
            <input
              type="radio"
              id="append"
              className="routeTypeRadio"
              value="2"
              checked={this.props.selectedRouteType === '2'}
              onChange={this.handleRoutingTypeSelection}
            />
            <label htmlFor="replace" className="routeTypeRadioLabel">
              Append To Route
            </label>
          </Fragment>
        )}
      </div>
    );
  }
}
