import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { withGoogleMap, GoogleMap, withScriptjs, Polygon } from 'react-google-maps';
const { DrawingManager } = require('react-google-maps/lib/components/drawing/DrawingManager');

const Markers = ({ restrictedAreas = [], ...props }) => {
  const map = useRef(null);

  const onPolygonCompleted = (polygon) => {
    props.setPolygon(polygon);
    props.hideDrawingManager();
  };

  if (props.hasNewDrawingManager) {
    props.polygon.setMap(null);
  }

  const mapClick = (lat, lng) => {
    // If scooter is in pick route location state
    if (props.showParkingSpotCreator) {
      props.pickParkingSpotLocationClick(lat, lng);
    }
  };

  const razorMapStyles = [
    { stylers: [{ saturation: '-20' }, { lightness: '15' }] },
    { featureType: 'poi.attraction', stylers: [{ saturation: '-100' }] },
    { featureType: 'poi.business', stylers: [{ saturation: '-100' }] },
    {},
    { featureType: 'poi.medical', stylers: [{ saturation: '-100' }] },
    {},
    { featureType: 'poi.place_of_worship', stylers: [{ saturation: '-100' }] },
    { featureType: 'poi.school', stylers: [{ saturation: '-100' }] },
    { featureType: 'poi.sports_complex', stylers: [{ saturation: '-100' }] },
    { featureType: 'road.highway', stylers: [{ saturation: '-20' }, { lightness: '20' }] },
    { featureType: 'transit', stylers: [{ saturation: '-100' }] },
  ];

  return (
    <GoogleMap
      defaultZoom={props.zoom}
      defaultCenter={props.center}
      options={{
        mapTypeControl: true,
        gestureHandling: 'greedy',
        styles: razorMapStyles,
      }}
      onCenterChanged={() => props.onDrag(map.current.getCenter())}
      onZoomChanged={() => props.onZoom(map.current.getZoom())}
      onClick={(e) => mapClick(e.latLng.lat(), e.latLng.lng())}
      ref={map}>
      {restrictedAreas.map((restrictedArea, index) => {
        const restrictedAreaCoords = restrictedArea.geofence.map((latlong) => {
          return {
            lat: latlong[0],
            lng: latlong[1],
          };
        });
        return (
          <Polygon
            key={`polygon_${index}`}
            path={restrictedAreaCoords}
            options={{
              clickable: false,
              fillColor: 'grey',
              fillOpacity: 0.5,
              strokeColor: '#552090',
              strokeOpacity: 1,
              strokeWeight: 1,
            }}
          />
        );
      })}
      {(props.hasDrawingManager || props.hasNewDrawingManager) && (
        <DrawingManager
          defaultOptions={{
            drawingControl: true,
            drawingControlOptions: {
              position: window.google.maps.ControlPosition.TOP_LEFT,
              drawingModes: [window.google.maps.drawing.OverlayType.POLYGON],
            },
            polygonOptions: {
              fillColor: `#0099E0`,
              fillOpacity: 0.5,
              strokeOpacity: 1.0,
            },
          }}
          onPolygonComplete={onPolygonCompleted}
        />
      )}
    </GoogleMap>
  );
};

Markers.propTypes = {
  center: PropTypes.object,
  hasDrawingManager: PropTypes.bool,
  hasNewDrawingManager: PropTypes.bool,
  hideDrawingManager: PropTypes.bool,
  onDrag: PropTypes.func,
  onZoom: PropTypes.func,
  pickParkingSpotLocationClick: PropTypes.func,
  polygon: PropTypes.object,
  restrictedAreas: PropTypes.array,
  setPolygon: PropTypes.func,
  showParkingSpotCreator: PropTypes.bool,
  zoom: PropTypes.number,
};

export default withScriptjs(withGoogleMap(Markers));
