import React from 'react';
import PropTypes from 'prop-types';

import iconAvailable from '../../images/icon-available.png';
import iconAvailableDelete from '../../images/icon-available-delete.png';
import iconCharging from '../../images/icon-charging.png';
import iconDamaged from '../../images/icon-damaged.png';
import iconDamagedCollected from '../../images/icon-damaged_collected.png';
import iconDamagedCollectedDelete from '../../images/icon-damaged_collected-delete.png';
import iconDamagedDelete from '../../images/icon-damaged-delete.png';
import iconDamagedFixed from '../../images/icon-damaged_fixed.png';
import iconDamagedFixedDelete from '../../images/icon-damaged_fixed-delete.png';
import iconEmergencyPickup from '../../images/icon-emergency_pickup.png';
import iconEmergencyPickupDelete from '../../images/icon-emergency_pickup-delete.png';
import iconInaccessible from '../../images/icon-inaccessible.png';
import iconInaccessibleDelete from '../../images/icon-inaccessible-delete.png';
import iconLowBattery from '../../images/icon-low_battery.png';
import iconLowBatteryDelete from '../../images/icon-low_battery-delete.png';
import iconMissing from '../../images/icon-missing.png';
import iconMissingDelete from '../../images/icon-missing-delete.png';
import iconPickedUp from '../../images/icon-picked_up.png';
import iconPickedUpDelete from '../../images/icon-picked_up-delete.png';
import iconPickupRequested from '../../images/icon-pickup_requested.png';
import iconPickupRequestedDelete from '../../images/icon-pickup_requested-delete.png';
import iconReserved from '../../images/icon-reserved.png';
import iconReservedDelete from '../../images/icon-reserved-delete.png';
import iconRiding from '../../images/icon-riding.png';
import iconRidingDelete from '../../images/icon-riding-delete.png';

const RoutedStatusCounts = ({
  handleDeleteStatusFromRoute,
  hasCreateRouteMode = false,
  routedVehicleCounts = {},
}) => {
  const _handleDeleteStatusFromRoute = (event) => {
    handleDeleteStatusFromRoute(event.target.name);
  };

  const imageData = {
    available: { src: iconAvailable, srcDelete: iconAvailableDelete },
    charging: { src: iconCharging, srcDelete: iconCharging },
    damaged_collected: { src: iconDamagedCollected, srcDelete: iconDamagedCollectedDelete },
    damaged_fixed: { src: iconDamagedFixed, srcDelete: iconDamagedFixedDelete },
    damaged: { src: iconDamaged, srcDelete: iconDamagedDelete },
    emergency_pickup: { src: iconEmergencyPickup, srcDelete: iconEmergencyPickupDelete },
    inaccessible: { src: iconInaccessible, srcDelete: iconInaccessibleDelete },
    low_battery: { src: iconLowBattery, srcDelete: iconLowBatteryDelete },
    missing: { src: iconMissing, srcDelete: iconMissingDelete },
    picked_up: { src: iconPickedUp, srcDelete: iconPickedUpDelete },
    pickup_requested: { src: iconPickupRequested, srcDelete: iconPickupRequestedDelete },
    reserved: { src: iconReserved, srcDelete: iconReservedDelete },
    riding: { src: iconRiding, srcDelete: iconRidingDelete },
  };

  let pins = [];
  for (const [key, value] of Object.entries(routedVehicleCounts)) {
    if (value > 0) {
      const data = imageData[key];
      const image = hasCreateRouteMode ? data.srcDelete : data.src;
      const onClick = hasCreateRouteMode ? _handleDeleteStatusFromRoute : undefined;
      pins.push(
        <div className="status-count-images" key={key}>
          <img src={image} alt={`icon-${key}`} name={key} onClick={onClick} />
          <span>{value}</span>
        </div>
      );
    }
  }
  return (
    <div className="selected-counts-container">
      <div className="selected-counts-labels">{pins}</div>
    </div>
  );
};

RoutedStatusCounts.propTypes = {
  handleDeleteStatusFromRoute: PropTypes.func,
  hasCreateRouteMode: PropTypes.bool,
  routedVehicleCounts: PropTypes.object,
};

export default RoutedStatusCounts;
