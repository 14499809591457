import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import '../ParkingSpotModal/index.scss';

const WarehouseModal = ({
  warehouseName = '',
  zoneName = '',
  ...props
}) => {
  const [name, setName] = useState(warehouseName);

  useEffect(() => setName(warehouseName), [warehouseName]);

  const onChangeGeofenceInputValues = (event) => {
    props.onChangeGeofenceInputValues(event);
  };

  return (
    <form className="edit-market-object-form">
      <div className="form-row">
        <label>Your warehouse will NOT be visible to your customers through your app. The warehouse will only show up for operators</label>
      </div>
      <div className="form-row">
        <label>Name:</label>
        <input
          type="text"
          name="warehouseName"
          placeholder={zoneName}
          value={name}
          onChange={onChangeGeofenceInputValues}
        />
      </div>
    </form>
  );
};

WarehouseModal.propTypes = {
  onChangeGeofenceInputValues: PropTypes.func,
  warehouseName: PropTypes.string,
  zoneName: PropTypes.string,
};

export default WarehouseModal;
