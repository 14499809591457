import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Fragment } from 'react';

class DeploymentSpotCard extends Component {
  render() {
    const { selectedDeploymentSpot, visible } = this.props;

    return visible ? (
      <Fragment>
        <div
          className="zone_map_card"
          style={{
            width: '100%',
            textAlign: 'center',
            border: '1px solid grey',
            marginTop: '20px',
          }}>
          <div
            style={{
              width: '200px',
              textAlign: 'center',
              backgroundColor: 'grey',
            }}
          />
          <table
            style={{
              marginLeft: '30px',
            }}>
            <tbody
              style={{
                textAlign: 'left',
              }}>
              <tr>
                <td>ID:</td>
                <th>{selectedDeploymentSpot.id}</th>
              </tr>
              <tr>
                <td>Notes:</td>
                <th>{selectedDeploymentSpot.notes}</th>
              </tr>
            </tbody>
          </table>
        </div>
        <div />
      </Fragment>
    ) : (
      <div />
    );
  }
}

DeploymentSpotCard.propTypes = {
  selectedDeploymentSpot: PropTypes.object,
  visible: PropTypes.bool,
};

export default DeploymentSpotCard;
