import React, { Component, Fragment } from 'react';

import './index.scss';

export default class RouteFiltersModal extends Component {
  handleStatusesChange = (event) => {
    this.props.handleStatusesChange(event);
  };

  handleBatteryPctChange = (event) => {
    this.props.handleBatteryPctChange(event);
  };

  handleSelectedBatteryPctChange = (event) => {
    this.props.handleSelectedBatteryPctChange(event);
  };

  handleStalenessChange = (event) => {
    this.props.handleStalenessChange(event);
  };

  handleStalenessOptionChange = (event) => {
    this.props.handleStalenessOptionChange(event);
  };

  handleSubzoneChange = (event) => {
    this.props.handleSubzoneChange(event);
  };

  handleMaxScootersChange = (val) => {
    this.props.handleMaxScootersChange(val);
  };

  getSubzonesRender = () => {
    let subzones = [];
    this.props.subzones.forEach((subzone, index) => {
      subzones.push(
        <div className="subzone_checkbox_container" key={`div_${index}`}>
          <input
            type="checkbox"
            name={subzone.id}
            value={subzone.id}
            onChange={this.handleSubzoneChange.bind(this)}
            key={`checkbox_${index}`}
          />
          <label className="subzone_checkbox">{subzone.name}</label>
        </div>
      );
    });
    return subzones;
  };

  render() {
    // reduce() function from https://stackoverflow.com/a/66002712/9523190
    const counts = this.props.vehicles
      ? this.props.vehicles
          .map((v) => v.status)
          .reduce((acc, curr) => ((acc[curr] = (acc[curr] || 0) + 1), acc), {})
      : {};
    let showSubzones = false;
    if (this.props.subzones.length > 0) {
      showSubzones = true;
    }
    return (
      <div className="modal">
        <h3> Pickup Type </h3>
        <input
          type="checkbox"
          name="pickup_requested"
          value={this.props.statuses.pickup_requested}
          onChange={this.handleStatusesChange.bind(this)}
        />
        <label className="form_input_section__label" htmlFor="is_active">
          {`Pickup Requested (${counts.pickup_requested || 0})`}
        </label>
        <input
          type="checkbox"
          name="damaged"
          value={this.props.statuses.damaged}
          onChange={this.handleStatusesChange.bind(this)}
        />
        <label className="form_input_section__label" htmlFor="is_active">
          {`Damaged (${counts.damaged || 0})`}
        </label>
        <input
          type="checkbox"
          name="low_battery"
          value={this.props.statuses.low_battery}
          onChange={this.handleStatusesChange.bind(this)}
        />
        <label className="form_input_section__label" htmlFor="is_active">
          {`Low Battery (${counts.low_battery || 0})`}
        </label>
        <input
          type="checkbox"
          name="missing"
          value={this.props.statuses.missing}
          onChange={this.handleStatusesChange.bind(this)}
        />
        <label className="form_input_section__label" htmlFor="is_active">
          {`Missing (${counts.missing || 0})`}
        </label>
        <input
          type="checkbox"
          name="reserved"
          value={this.props.statuses.reserved}
          onChange={this.handleStatusesChange.bind(this)}
        />
        <label className="form_input_section__label" htmlFor="is_active">
          {`Reserved (${counts.reserved || 0})`}
        </label>
        <input
          type="checkbox"
          name="inaccessible"
          value={this.props.statuses.inaccessible}
          onChange={this.handleStatusesChange.bind(this)}
        />
        <label className="form_input_section__label" htmlFor="is_active">
          {`Inaccessible (${counts.inaccessible || 0})`}
        </label>
        <input
          type="checkbox"
          name="emergency_pickup"
          value={this.props.statuses.emergency_pickup}
          onChange={this.handleStatusesChange.bind(this)}
        />
        <label className="form_input_section__label" htmlFor="emergecny_pickup">
          {`Emergency Pickup (${counts.emergency_pickup || 0})`}
        </label>
        <Fragment>
          <br />
          <span className="status-cnts"> Available: {counts.available || 0} </span>
          <br />
          <input
            type="checkbox"
            name="isBatteryPctSelected"
            value={this.props.isBatteryPctSelected}
            onChange={this.handleBatteryPctChange.bind(this)}
          />
          <label className="battery-input-lable" htmlFor="is_active">
            include batteries up to
          </label>
          <input
            type="text"
            className="battery-pct-input"
            placeholder={this.props.selectedBatteryPct}
            name="battery_pct"
            onChange={this.handleSelectedBatteryPctChange.bind(this)}
          />
          <label className="form_input_section__label" htmlFor="is_active">
            percent
          </label>
        </Fragment>
        <div className="radio">
          <input
            type="checkbox"
            name="isStaleSelected"
            value={this.props.isStaleSelected}
            onChange={this.handleStalenessChange.bind(this)}
          />
          <label className="input_section__label" htmlFor="isStaleSelected">
            Include scooters with stale status for at least
          </label>
          <label>
            <input
              type="radio"
              value="1"
              checked={this.props.selectedStalenessOption === '1'}
              onChange={this.handleStalenessOptionChange.bind(this)}
            />
            24 hours
          </label>
          <label>
            <input
              type="radio"
              value="2"
              checked={this.props.selectedStalenessOption === '2'}
              onChange={this.handleStalenessOptionChange.bind(this)}
            />
            48 hours
          </label>
          <label>
            <input
              type="radio"
              value="3"
              checked={this.props.selectedStalenessOption === '3'}
              onChange={this.handleStalenessOptionChange.bind(this)}
            />
            72 hours
          </label>
          <br />
          <br />
          <h3>Maximum Number of Scooters </h3>
          <input
            type="text"
            className="max-scooters-input"
            placeholder="60"
            width="20px"
            name="max_scooters"
            onChange={(e) => this.handleMaxScootersChange(e.target.value)}
          />
          {showSubzones && (
            <Fragment>
              <div className="subzone-title-lbl">Select Subzone</div>
              {this.getSubzonesRender()}
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}
