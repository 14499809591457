import React from 'react';
import PropTypes from 'prop-types';

import iconAvailable from '../../images/icon-available.png';
import iconRiding from '../../images/icon-riding.png';
import iconLowBattery from '../../images/icon-low_battery.png';
import iconPickupRequested from '../../images/icon-pickup_requested.png';
import iconCharging from '../../images/icon-charging.png';
import iconPickedUp from '../../images/icon-picked_up.png';
import iconDamaged from '../../images/icon-damaged.png';
import iconDamagedCollected from '../../images/icon-damaged_collected.png';
import iconDamagedFixed from '../../images/icon-damaged_fixed.png';
import iconInaccessible from '../../images/icon-inaccessible.png';
import iconReserved from '../../images/icon-reserved.png';
import iconEmergencyPickup from '../../images/icon-emergency_pickup.png';

import './index.scss';

const StatusCounts = ({ vehicles = [] }) => {
  // reduce() function from https://stackoverflow.com/a/66002712/9523190
  const counts = vehicles
    .map((v) => v.status)
    .reduce((acc, curr) => ((acc[curr] = (acc[curr] || 0) + 1), acc), {});

  return (
    <div className="status-counts-container">
      <div className="status-labels">
        <div className="status-count-images">
          <img src={iconAvailable} alt="icon-available" />
          {counts.available || 0}
        </div>
        <div className="status-count-images">
          <img src={iconRiding} alt="icon-riding" />
          {counts.riding || 0}
        </div>
        <div className="status-count-images">
          <img src={iconLowBattery} alt="icon-low_battery" />
          {counts.low_battery || 0}
        </div>
        <div className="status-count-images">
          <img src={iconPickupRequested} alt="icon-pickup_requested" />
          {counts.pickup_requested || 0}
        </div>
        <div className="status-count-images">
          <img src={iconCharging} alt="icon-charging" />
          {counts.charging || 0}
        </div>
        <div className="status-count-images">
          <img src={iconPickedUp} alt="icon-picked_up" />
          {counts.picked_up || 0}
        </div>
        <div className="status-count-images">
          <img src={iconDamaged} alt="icon-damaged" />
          {counts.damaged || 0}
        </div>
        <div className="status-count-images">
          <img src={iconDamagedCollected} alt="icon-damaged_collected" />
          {counts.damaged_collected || 0}
        </div>
        <div className="status-count-images">
          <img src={iconDamagedFixed} alt="icon-damaged_fixed" />
          {counts.damaged_fixed || 0}
        </div>
        <div className="status-count-images">
          <img src={iconInaccessible} alt="icon-emergency_pickup" />
          {counts.inaccessible || 0}
        </div>
        <div className="status-count-images">
          <img src={iconReserved} alt="icon-reserved" />
          {counts.reserved || 0}
        </div>
        <div className="status-count-images">
          <img src={iconEmergencyPickup} alt="icon-emergency_pickup" />
          {counts.emergency_pickup || 0}
        </div>
      </div>
    </div>
  );
};

StatusCounts.propTypes = {
  vehicles: PropTypes.array.isRequired,
};

export default StatusCounts;
