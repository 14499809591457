import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Toggle from 'react-toggle';

import './index.scss';

const ParkingSpotModal = ({
  selectedParkingSpot,
  selectedParkingSpotShowOnMap,
  selectedParkingSpotNotes,
  selectedParkingSpotCapacity,
  selectedParkingSpotMinimumVehicles,
  ...props
}) => {
  const [notes, setNotes] = useState(selectedParkingSpotNotes);
  const [capacity, setCapacity] = useState(selectedParkingSpotCapacity);
  const [showOnMap, setShowOnMap] = useState(selectedParkingSpotShowOnMap);
  const [minimumVehicles, setMinimumVehicles] = useState(selectedParkingSpotMinimumVehicles);

  useEffect(() => {
    setShowOnMap(selectedParkingSpotShowOnMap);
  }, [selectedParkingSpotShowOnMap]);

  useEffect(() => {
    setNotes(selectedParkingSpotNotes);
  }, [selectedParkingSpotNotes]);

  useEffect(() => {
    setCapacity(selectedParkingSpotCapacity);
  }, [selectedParkingSpotCapacity]);

  useEffect(() => {
    setMinimumVehicles(selectedParkingSpotMinimumVehicles);
  }, [selectedParkingSpotMinimumVehicles]);

  const onChangeParkingSpotInputValues = (event) => {
    props.onChangeParkingSpotInputValues(event);
  };

  const onChangeParkingSpotSegmentValues = (event) => {
    props.onChangeParkingSpotSegmentValues(event);
  };

  return (
    <form className="edit-market-object-form">
      <div className="form-row">
        <label>ID:</label>
        <p>{selectedParkingSpot.id}</p>
      </div>
      <div className="form-row">
        <label>Notes</label>
        <input
          className="add_pricing_plan_form__data_input"
          type="text"
          name="selectedParkingSpotNotes"
          value={notes}
          onChange={onChangeParkingSpotInputValues}
        />
      </div>
      <div className="form-row">
        <label>Capacity</label>
        <input
          className="add_pricing_plan_form__data_input"
          type="text"
          name="selectedParkingSpotCapacity"
          value={capacity}
          onChange={onChangeParkingSpotInputValues}
        />
      </div>
      <div className="form-row">
        <label>Minimum Vehicles</label>
        <input
          className="add_pricing_plan_form__data_input"
          type="text"
          name="selectedParkingSpotMinimumVehicles"
          value={minimumVehicles}
          onChange={onChangeParkingSpotInputValues}
        />
      </div>
      <div className="form-row">
        <label>Show on map:</label>
        <Toggle
          id="parking_toggle"
          name="selectedParkingSpotShowOnMap"
          checked={showOnMap}
          onChange={onChangeParkingSpotSegmentValues}
          className="toggle"
        />
      </div>
      <div className="form-row">
        <label>Created:</label>
        <p>{selectedParkingSpot.created_date}</p>
      </div>
      <div className="form-row">
        <label>Edited:</label>
        <p>{selectedParkingSpot.modified_date}</p>
      </div>
    </form>
  );
};

ParkingSpotModal.propTypes = {
  onChangeParkingSpotInputValues: PropTypes.func,
  onChangeParkingSpotSegmentValues: PropTypes.func,
  selectedParkingSpot: PropTypes.object,
  selectedParkingSpotCapacity: PropTypes.string,
  selectedParkingSpotMinimumVehicles: PropTypes.string,
  selectedParkingSpotNotes: PropTypes.string,
  selectedParkingSpotShowOnMap: PropTypes.bool,
};

export default ParkingSpotModal;
